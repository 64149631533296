import React from 'react'
import ArrivalProducts from './arrival-products/ArrivalProducts'
import Blogs from './blogs/Blogs'
import Clients from './clients/Clients'
import Features from './features/Features'
import Offer from './offer/Offer'
import Products from './products/Products'
import SubProducts from './products/sub-products/SubProducts'
import Slider from './slider/Slider'

function HomeApp() {
  return (
    <>
        <Slider />
        <Features />
        <Products />
        <SubProducts />
        <ArrivalProducts />
        <Offer />
        <Blogs />
        <Clients />
    </>
  )
}

export default HomeApp
import React from "react";
import GoldRate from "../../components/gold-rate/GoldRate";

function GoldRatePage() {
  return (
    <>
      <GoldRate />
    </>
  );
}

export default GoldRatePage;

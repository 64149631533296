import React from 'react'
import ProductsNosidebar from '../../components/products-nosidebar/ProductsNosidebar'

function ProductsNosidebarPage() {
  return (
    <>
      <ProductsNosidebar />
    </>
  )
}

export default ProductsNosidebarPage
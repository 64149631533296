import Accordion from 'react-bootstrap/Accordion';
function BillingPaymentMethod() {
  return (
    <>
      <div className="col-lg-6">
        <div className="tm-checkout-orderinfo">
          <div className="table-responsive">
            <table className="table table-borderless tm-checkout-ordertable">
              <thead>
                <tr>
                  <th>Product</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Cosmetic plastic compact powder * 1</td>
                  <td>$75.00</td>
                </tr>
                <tr>
                  <td>Cosmetics and makeup brushes * 1</td>
                  <td>$70.50</td>
                </tr>
              </tbody>
              <tfoot>
                <tr className="tm-checkout-subtotal">
                  <td>Cart Subtotal</td>
                  <td>$175.00</td>
                </tr>
                <tr className="tm-checkout-shipping">
                  <td>(+) Shipping Charge</td>
                  <td>$15.00</td>
                </tr>
                <tr className="tm-checkout-total">
                  <td>Total</td>
                  <td>$190.00</td>
                </tr>
              </tfoot>
            </table>
          </div>
          
          {/* accordion---part--- */}
          <div className="tm-checkout-payment">
            <h4>Select Payment Method</h4>
            <div className="tm-form-inner">
              <div className="tm-form-field">
                <input type="radio" name="checkout-payment-method" id="checkout-payment-banktransfer"/>
                  <label htmlFor="checkout-payment-banktransfer">Direct Bank Transfer</label>
                  <div className="tm-checkout-payment-content">
                    <p>Make your payment directly into our bank account.</p>
                  </div>
              </div>
              <div className="tm-form-field">
                <input type="radio" name="checkout-payment-method" id="checkout-payment-checkpayment" defaultChecked="checked"/>
                  <label htmlFor="checkout-payment-checkpayment">Check Payments</label>
                <div className="tm-checkout-payment-content" style={{ display: "block" }}>
                    <p>Please send a check to Store Name, Store Street, Store Town,
                      Store State / County, Store Postcode.</p>
                  </div>
              </div>
              <div className="tm-form-field">
                <input type="radio" name="checkout-payment-method" id="checkout-payment-cashondelivery"/>
                  <label htmlFor="checkout-payment-cashondelivery">Cash On Delivery</label>
                  <div className="tm-checkout-payment-content">
                    <p>Pay with cash upon delivery.</p>
                  </div>
              </div>
              <div className="tm-form-field">
                <input type="radio" name="checkout-payment-method" id="checkout-payment-paypal"/>
                  <label htmlFor="checkout-payment-paypal">PayPal</label>
                  <div className="tm-checkout-payment-content">
                    <p>Pay via PayPal.</p>
                  </div>
              </div>
              <div className="tm-form-field">
                <input type="radio" name="checkout-payment-method" id="checkout-payment-creditcard"/>
                  <label htmlFor="checkout-payment-creditcard">Credit Card</label>
                  <div className="tm-checkout-payment-content">
                    <p>Pay with your credit card via Stripe.</p>
                  </div>
              </div>
            </div>
          </div>
          <div className="tm-checkout-submit">
            <p>Your personal data will be used to process your order, support your
              experience throughout this website, and htmlFor other purposes described in our
              privacy policy.</p>
            <div className="tm-form-inner">
              <div className="tm-form-field">
                <input type="checkbox" name="checkout-read-terms" id="checkout-read-terms"/>
                  <label htmlFor="checkout-read-terms">I have read and agree to the website
                    terms and conditions</label>
              </div>
              <div className="tm-form-field">
                <button type="submit" className="tm-button ml-auto">Place Order</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default BillingPaymentMethod;
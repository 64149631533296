import React from 'react'
import Breadcrumbs from '../../shared/breadcrumbs/Breadcrumbs'
import LoginRegister from "../../components/login-register/LoginRegister";
function LoginRegisterPage() {
  return (
    <>
        <Breadcrumbs title="Login Register" />
        <LoginRegister />
    </>
  )
}

export default LoginRegisterPage
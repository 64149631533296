import React from 'react'
import MyAccount from '../../components/my-account/MyAccount'
import Breadcrumbs from '../../shared/breadcrumbs/Breadcrumbs'

function AccountPage() {
  return (
    <>
        <Breadcrumbs title="Account" />
        <MyAccount />
    </>
  )
}

export default AccountPage
import React from "react";
import Breadcrumbs from "../../shared/breadcrumbs/Breadcrumbs";
import MainProducts from "../prodcuts/main-products/MainProducts";
import Widgets from "../prodcuts/widgets/Widgets";

function ProductsLeftSidebar() {
  return (
    <>
      <Breadcrumbs title="products" />
      {/* Page Content */}
      <main className="page-content">
        {/* Products Wrapper */}
        <div className="tm-products-area tm-section tm-padding-section bg-white">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-12">
                <Widgets />
              </div>
              <div className="col-lg-9 col-12">
                <MainProducts />
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default ProductsLeftSidebar;

import BlogAside from "../blog/blog-aside/BlogAside";
import BlogBody from "../blog/blog-body/BlogBody";
import BlogDetailsLeft from "./blog-details-left/BlogDetailsLeft";
import BlogDetailsRight from "./blog-details-right/BlogDetailsRight";

function BlogDetails(){
  return(
    <>
      <main class="page-content">       
        <div class="tm-section tm-blog-details-page tm-padding-section bg-white">
          <div class="container">
            <div class="row">
              {/* <BlogDetailsLeft />
              <BlogDetailsRight/> */}
              <BlogAside/>
              <BlogBody/>
            </div>
          </div>
        </div>
      </main>
    </>
  )
}
export default BlogDetails; 
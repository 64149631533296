import React from "react";
import OwlCarousel from "react-owl-carousel";

import brandlogo1 from "../../../../assets/images/brandlogo-1.png";
import brandlogo2 from "../../../../assets/images/brandlogo-2.png";
import brandlogo3 from "../../../../assets/images/brandlogo-3.png";
import brandlogo4 from "../../../../assets/images/brandlogo-4.png";
import brandlogo5 from "../../../../assets/images/brandlogo-5.png";

function Client() {
  return (
    <>
      <div className="slider index-3">
        <OwlCarousel
          className="owl-theme"
          loop
          margin={30}
          autoplayTimeout={1000}
          items={5}
          autoplay
          dots={false}
        >
          {/* Brang Logo Single */}
          <div className="col-12 tm-brandlogo item">
            <a href="#">
              <img src={brandlogo1} alt="brand-logo" />
            </a>
          </div>
          {/* Brang Logo Single */}

          {/* Brang Logo Single */}
          <div className="col-12 tm-brandlogo item">
            <a href="#">
              <img src={brandlogo2} alt="brand-logo" />
            </a>
          </div>
          {/* Brang Logo Single */}

          {/* Brang Logo Single */}
          <div className="col-12 tm-brandlogo item">
            <a href="#">
              <img src={brandlogo3} alt="brand-logo" />
            </a>
          </div>
          {/* Brang Logo Single */}

          {/* Brang Logo Single */}
          <div className="col-12 tm-brandlogo item">
            <a href="#">
              <img src={brandlogo4} alt="brand-logo" />
            </a>
          </div>
          {/* Brang Logo Single */}

          {/* Brang Logo Single */}
          <div className="col-12 tm-brandlogo item">
            <a href="#">
              <img src={brandlogo5} alt="brand-logo" />
            </a>
          </div>
          {/* Brang Logo Single */}

          {/* Brang Logo Single */}
          <div className="col-12 tm-brandlogo item">
            <a href="#">
              <img src={brandlogo1} alt="brand-logo" />
            </a>
          </div>
          {/* Brang Logo Single */}

          {/* Brang Logo Single */}
          <div className="col-12 tm-brandlogo item">
            <a href="#">
              <img src={brandlogo2} alt="brand-logo" />
            </a>
          </div>
          {/* Brang Logo Single */}

          {/* Brang Logo Single */}
          <div className="col-12 tm-brandlogo item">
            <a href="#">
              <img src={brandlogo3} alt="brand-logo" />
            </a>
          </div>
          {/* Brang Logo Single */}

          {/* Brang Logo Single */}
          <div className="col-12 tm-brandlogo item">
            <a href="#">
              <img src={brandlogo4} alt="brand-logo" />
            </a>
          </div>
          {/* Brang Logo Single */}

          {/* Brang Logo Single */}
          <div className="col-12 tm-brandlogo item">
            <a href="#">
              <img src={brandlogo5} alt="brand-logo" />
            </a>
          </div>
          {/* Brang Logo Single */}
        </OwlCarousel>
      </div>
    </>
  );
}

export default Client;

import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { ImCross } from "react-icons/im";
import { TbCertificate, TbTruckDelivery } from "react-icons/tb";

function ProductDetailsContent() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <div className="tm-prodetails-content">
        <h4 className="tm-prodetails-title">Stylist Diamond Ring</h4>
        <div className="tm-prodetails-price prodetails-price">
          <div className="currentPrice">
            <del>$75.99</del> $59.99
          </div>
          <div className="viewPriceBtn">
            <Button onClick={handleShow} className="viewPrice">
              View Price Breakup
            </Button>
          </div>
        </div>
        <div className="tm-ratingbox">
          <span className="is-active">
            <i className="ion-android-star-outline"></i>
          </span>
          <span className="is-active">
            <i className="ion-android-star-outline"></i>
          </span>
          <span className="is-active">
            <i className="ion-android-star-outline"></i>
          </span>
          <span className="is-active">
            <i className="ion-android-star-outline"></i>
          </span>
          <span>
            <i className="ion-android-star-outline"></i>
          </span>
        </div>
        <div className="tm-prodetails-infos">
          <div className="tm-prodetails-singleinfo">
            <b>Product ID : </b>010
          </div>
          <div className="tm-prodetails-singleinfo">
            <b>Category : </b>
            <a href="#">Ring</a>
          </div>
          <div className="tm-prodetails-singleinfo tm-prodetails-tags">
            <b>Tags : </b>
            <ul>
              <li>
                <a href="#">bracelets</a>
              </li>
              <li>
                <a href="#">diamond</a>
              </li>
              <li>
                <a href="#">ring</a>
              </li>
              <li>
                <a href="#">necklaces</a>
              </li>
            </ul>
          </div>
          <div className="tm-prodetails-singleinfo">
            <b>Available : </b>
            <span className="color-theme">In Stock</span>
          </div>
          <div className="tm-prodetails-singleinfo tm-prodetails-share">
            <b>Share : </b>
            <ul>
              <li>
                <a href="#">
                  <i className="ion-social-facebook"></i>
                </a>
              </li>
              <li>
                <a href="#">
                  <i className="ion-social-instagram-outline"></i>
                </a>
              </li>
              <li>
                <a href="#">
                  <i className="ion-social-skype-outline"></i>
                </a>
              </li>
              <li>
                <a href="#">
                  <i className="ion-social-pinterest-outline"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="product-content">
          <div className="product-details-card">
            <div>
              <div className="product-details-card-inner">
                <div className="product-details">
                  <div className="details-inner-box">
                    <h5>
                      <strong>Product Information</strong>
                    </h5>
                    <div className="product-material-wrapper">
                      <div className="product-material-item">
                        <div className="product-material-label">Purity</div>
                        <div>18 KT Yellow Gold</div>
                      </div>
                      <div className="product-material-item">
                        <div className="product-material-label">Weight</div>
                        <div>32.054 gm</div>
                      </div>
                    </div>
                  </div>
                  <div className="details-inner-box">
                    <h6 className="product-material-title">Metal Details</h6>
                    <div className="product-material-wrapper">
                      <div className="product-material-item">
                        <div className="product-material-label">Type</div>
                        <div>GOLD</div>
                      </div>
                      <div className="product-material-item">
                        <div className="product-material-label">Quality</div>
                        <div>18 KT Yellow Gold</div>
                      </div>
                      <div className="product-material-item">
                        <div className="product-material-label">Weight</div>
                        <div>32.054 gm</div>
                      </div>
                    </div>
                  </div>
                  <div className="details-inner-box full-width">
                    <h6 className="product-material-title">Diamond Details</h6>
                    <div className="product-material-wrapper">
                      <div className="product-material-item">
                        <div className="product-material-label">Quality</div>
                        <div>IJ SI</div>
                      </div>
                      <div className="product-material-item">
                        <div className="product-material-label">Shape</div>
                        <div>Round</div>
                      </div>
                      <div className="product-material-item">
                        <div className="product-material-label">Total Pcs</div>
                        <div>64 pcs</div>
                      </div>
                      <div className="product-material-item">
                        <div className="product-material-label">
                          Total Weight
                        </div>
                        <div>0.5 ct</div>
                      </div>
                    </div>
                    <div className="product-material-wrapper">
                      <div className="product-material-item">
                        <div>IJ SI</div>
                      </div>
                      <div className="product-material-item">
                        <div>Round</div>
                      </div>
                      <div className="product-material-item">
                        <div>160 pcs</div>
                      </div>
                      <div className="product-material-item">
                        <div>1.63 ct</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className>
                <div className="sec-title-wrap">
                  <h5>
                    <strong>Shipping Details</strong>
                  </h5>
                </div>
                <div className="product-shipping-details grid grid-2 grid-gap-30">
                  <div>
                    <TbCertificate />
                    <span>100% Certified</span>
                  </div>
                  <div>
                    <TbTruckDelivery />
                    <span>Fast Delivery</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quis quemi
          dolor, malesuada id metus a, mattis eleifend elit. Nullam pharetra
          consequat ex in dapibus. Vestibulum ante ipsum primis in faucibus
          orciluctus curae.
        </p>
        <div className="tm-prodetails-quantitycart">
          <h6>Quantity :</h6>
          <div className="tm-quantitybox">
            <input type="text" value="1" />
            <div className="decrement-button tm-quantitybox-button">-</div>
            <div className="increment-button tm-quantitybox-button">+</div>
          </div>
          {/* <div className="tm-quantitybox">
            <input type="text" value="1" onChange={handleChange}   />
          </div> */}
          <a href="#" className="tm-button tm-button-dark">
            Add To Cart
          </a>
        </div>
      </div>

      {/* Modal */}
      <Modal show={show} onHide={handleClose} dialogClassName="viewPriceModal">
        <div closeButton onClick={handleClose} className="modal-header">
          <ImCross />
        </div>
        <Modal.Body>
          <div className="priceBody">
            <table className="table">
              <thead className="datatable-thead">
                <tr>
                  <th>Component</th>
                  <th>Rate</th>
                  <th>Weight</th>
                  <th>Value</th>
                  <th>Discount</th>
                  <th>Final Value</th>
                </tr>
              </thead>
              <tbody className="datatable-tbody">
                <tr className="p-rowgroup-header">
                  <td colSpan={6}>
                    <span>GOLD</span>
                  </td>
                </tr>
                <tr className="product-detail-dec-block">
                  <td>18 KT Yellow Gold</td>
                  <td>₹ 4818</td>
                  <td>32.054 gm</td>
                  <td>₹ 1,54,431</td>
                  <td>-</td>
                  <td>₹ 1,54,431</td>
                </tr>
                <tr className="product-detail-dec-block">
                  <td>Total GOLD Value</td>
                  <td />
                  <td>-</td>
                  <td>₹ 1,54,431 </td>
                  <td>-</td>
                  <td>₹ 1,54,431 </td>
                </tr>
                <tr className="p-rowgroup-header">
                  <td colSpan={6}>
                    <span>Diamond</span>
                  </td>
                </tr>
                <tr className="product-detail-dec-block">
                  <td>IJ SI Round (64 pcs)</td>
                  <td>₹ 26000</td>
                  <td>0.50 ct </td>
                  <td>₹ 13,000 </td>
                  <td>-</td>
                  <td />
                </tr>
                <tr className="product-detail-dec-block">
                  <td>IJ SI Round (160 pcs)</td>
                  <td>₹ 25000</td>
                  <td>1.63 ct </td>
                  <td>₹ 40,750 </td>
                  <td>-</td>
                  <td />
                </tr>
                <tr className="product-detail-dec-block">
                  <td>Total Diamond Value</td>
                  <td />
                  <td>-</td>
                  <td>₹ 53,750</td>
                  <td />
                  <td>₹ 53,750</td>
                </tr>
                <tr className="p-rowgroup-header">
                  <td colSpan={6}>
                    <span>Other Charges</span>
                  </td>
                </tr>
                <tr className="product-detail-dec-block">
                  <td>Making Charges</td>
                  <td>₹ 700</td>
                  <td>32.054 gm </td>
                  <td>₹ 22,438 </td>
                  <td />
                  <td>₹ 22,438 </td>
                </tr>
                <tr className="product-detail-dec-block">
                  <td>Hallmark Charges</td>
                  <td>-</td>
                  <td>-</td>
                  <td>₹ 100 </td>
                  <td />
                  <td>₹ 100 </td>
                </tr>
                <tr className="product-detail-dec-block">
                  <td>Stamping Charges</td>
                  <td>-</td>
                  <td>-</td>
                  <td>₹ 0 </td>
                  <td />
                  <td>₹ 0 </td>
                </tr>
                <tr className="product-detail-dec-block">
                  <td>Certificate Charges</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td />
                  <td>₹ 1040</td>
                </tr>
                <tr className="p-rowgroup-footer">
                  <td>Grand Total</td>
                  <td>-</td>
                  <td>-</td>
                  <td>₹ 2,31,759</td>
                  <td />
                  <td>₹ 2,31,759</td>
                </tr>
              </tbody>
            </table>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ProductDetailsContent;

import React from "react";
import Client from "./client/Client";

function Clients() {
  return (
    <>
      {/* Brand Logos */}
      <div className="tm-section tm-brandlogo-area tm-padding-section bg-grey">
        <div className="container">
          <div className="row tm-brandlogo-slider">
            <Client />
          </div>
        </div>
      </div>
      {/* Brand Logos */}
    </>
  );
}

export default Clients;

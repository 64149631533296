import React from 'react'
import Menu from './menu/Menu'
import MiddleHeader from './middle-header/MiddleHeader'
import TopHeader from './top-header/TopHeader'

function Header() {
  return (
    <>
      <TopHeader />
      <MiddleHeader />
      <Menu />
    </>
  )
}

export default Header

function LoginRegister() {
  return (
    <>
      <main className="page-content">

        {/* <!-- Login Register Area --> */}
        <div className="tm-section tm-login-register-area bg-white tm-padding-section">
          <div className="container">
            <div className="row">

              <div className="col-lg-6">
                <form action="#" className="tm-form tm-login-form">
                  <h4>Login</h4>
                  <p>Become a part of our community!</p>
                  <div className="tm-form-inner">
                    <div className="tm-form-field">
                      <label htmlFor="login-email">Username or email address*</label>
                      <input type="email" id="login-email" required="required" />
                    </div>
                    <div className="tm-form-field">
                      <label htmlFor="login-password">Password*</label>
                      <input type="password" id="login-password" required="required" />
                    </div>
                    <div className="tm-form-field">
                      <input type="checkbox" name="login-remember" id="login-remember" />
                      <label htmlFor="login-remember">Remember Me</label>
                      <p className="mb-0"><a href="#">Forgot your password?</a></p>
                    </div>
                    <div className="tm-form-field">
                      <button type="submit" className="tm-button">Login</button>
                    </div>
                    <div className="tm-form-field">
                      <div className="tm-form-sociallogin">
                        <h6>Or, Login with :</h6>
                        <ul>
                          <li><a href="#" className="facebook-btn"><i className="ion-social-facebook"></i></a></li>
                          <li><a href="#" className="google-btn"><i className="ion-social-google"></i></a></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </form>
              </div>

              <div className="col-lg-6">
                <form action="#" className="tm-form tm-register-form">
                  <h4>Create an account</h4>
                  <p>Welcome! Register for an account</p>
                  <div className="tm-form-inner">
                    <div className="tm-form-field">
                      <label htmlFor="register-username">Username</label>
                      <input type="text" id="register-username" required="required" />
                    </div>
                    <div className="tm-form-field">
                      <label htmlFor="register-email">Email address</label>
                      <input type="email" id="register-email" required="required" />
                    </div>
                    <div className="tm-form-field">
                      <label htmlFor="register-password">Password</label>
                      <input type="password" id="register-password" name="register-pass" required="required" />
                    </div>
                    <div className="tm-form-field">
                      <div>
                        <input type="checkbox" id="register-pass-show" name="register-pass-show" />
                        <label htmlFor="register-pass-show">Show Password</label>
                      </div>
                      <div>
                        <input type="checkbox" id="register-terms" name="register-terms" />
                        <label htmlFor="register-terms">I have read and agree to the website <a href="#">terms and
                          conditions</a></label>
                      </div>
                    </div>
                    <div className="tm-form-field">
                      <button type="submit" className="tm-button">Register</button>
                    </div>
                  </div>
                </form>
              </div>

            </div>
          </div>
        </div>
        {/* <!--// Login Register Area --> */}

      </main>
    </>
  )
}
export default LoginRegister;
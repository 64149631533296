import React from "react";
import offerImage from "../../../../assets/images/offer-image-1.png";

function OfferFigure() {
  return (
    <>
      <div className="tm-offer-image">
        <img
          className="tm-offer"
          src={offerImage}
          alt="offer image"
        />
      </div>
    </>
  );
}

export default OfferFigure;

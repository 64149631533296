import React from "react";
import breadcrumbImg from "../../assets/images/breadcrumb-bg.jpg"

import "./Breadcrumbs.css"
const bgImg = {
    backgroundImage: `url(${breadcrumbImg})`,
}
function Breadcrumbs(props) {
  return (
    <>
      {/* Breadcrumb Area */}
      <div
        className="tm-breadcrumb-area tm-padding-section bg-grey"
        style={bgImg}
      >
        <div className="container">
          <div className="tm-breadcrumb">
            <h2>{props.title}</h2>
            <ul>
              <li>
                <a href="#">Home</a>
              </li>
              <li>{props.title}</li>
            </ul>
          </div>
        </div>
      </div>
      {/* Breadcrumb Area */}
    </>
  );
}

export default Breadcrumbs;

import React from 'react'
import HomeApp from '../../components/home/HomeApp'

function HomePage() {
  return (
    <>
        <HomeApp />
    </>
  )
}

export default HomePage
import React from 'react'
import Blog from '../../components/blog/Blog'
import Breadcrumbs from '../../shared/breadcrumbs/Breadcrumbs'

function BlogPage() {
  return (
    <>
        <Breadcrumbs title="Blog" />
        <Blog />
    </>
  )
}

export default BlogPage
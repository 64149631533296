// Assets
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "ionicons-npm/css/ionicons.min.css"
import "boxicons"
import { Route, Routes } from "react-router-dom";
import "./assets/css/style.css";

// Components
import Footer from "./common/footer/Footer";
import Header from "./common/header/Header";
import AboutPage from "./pages/about";
import ContactPage from "./pages/contact";
import GoldRatePage from "./pages/gold-rate";
import Home from "./pages/home";
import PortfolioPage from "./pages/portfolio/Portfolio";
import ProductsPage from "./pages/products";
import ProductsLeftSidebarPage from "./pages/products-leftsidebar";
import ProductsNosidebarPage from "./pages/products-nosidebar";
import Products4ColumnPage from "./pages/products-4-column";
import ProductDetailsPage from "./pages/product-details";
import WishlistPage from "./pages/wishlist";
import ProductDetailsLeftsidebarPage from "./pages/product-details-leftsidebar";
import ProductDetailsNosidebarPage from "./pages/product-details-nosidebar";
import BackTop from "./components/back-to-top/BackTop";
import { useEffect, useState } from "react";
import Preloader from "./components/preloader/Preloader";
import CartPage from "./pages/cart";
import CheckoutPage from "./pages/checkout";
import AccountPage from "./pages/account";
import LoginRegisterPage from "./pages/login-register";
import BlogPage from "./pages/blog";
import BlogLeftSidebarPage from "./pages/blog-left-sidebar";

function App() {
  const [isLoading, setIsLoading] = useState(true);
  useEffect(()=>{
    setTimeout(()=>{
      setIsLoading(false)
    }, 2000)
  })
  return (
    <>
    {isLoading ? <Preloader /> : ""}
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/products" element={<ProductsPage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/products" element={<ProductsPage />} />
        <Route path="/portfolios" element={<PortfolioPage />} />
        <Route path="/products-leftsidebar" element={<ProductsLeftSidebarPage />} />
        <Route path="/products-nosidebar" element={<ProductsNosidebarPage />} />
        <Route path="/products-4-column" element={<Products4ColumnPage />} />
        <Route path="/product-details" element={<ProductDetailsPage />} />
        <Route path="/product-details-leftsidebar" element={<ProductDetailsLeftsidebarPage />} />
        <Route path="/product-details-nosidebar" element={<ProductDetailsNosidebarPage />} />
        <Route path="/wishlist" element={<WishlistPage />} />
        <Route path="/cart" element={<CartPage />} />
        <Route path="/checkout" element={<CheckoutPage />} />
        <Route path="/account" element={<AccountPage />} />
        <Route path="/login-register" element={<LoginRegisterPage />} />
        <Route path="/blog" element={<BlogPage />} />
        <Route path="/blog-left-sidebar" element={<BlogLeftSidebarPage />} />
      </Routes>
      <Footer />
      <BackTop />
    </>
  );
}

export default App;

import React from 'react'
import ProductDetails from '../../components/product-details/ProductDetails'

function ProductDetailsPage() {
  return (
    <>
        <ProductDetails />
    </>
  )
}

export default ProductDetailsPage
import React from "react";

const AskQuestion = () => {
  return (
    <>
      <div className="askQuestion">
        <h4>Have a Question?</h4>

        <form noValidate>
          <div className="ask-question-form row">
            <div className="col-md-6">
              <div className="form-group mb-3">
                <label>
                  Name<span className="asterisk">*</span>
                </label>
                <input
                  placeholder="Enter name"
                  name="name"
                  autoComplete="off"
                  minLength={3}
                  maxLength={40}
                  className="p-inputtext p-component p-filled"
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group mb-3">
                <label>
                  Email<span className="asterisk">*</span>
                </label>
                <input
                  placeholder="Enter your email"
                  name="email"
                  autoComplete="off"
                  id="email"
                  className="p-inputtext p-component p-filled"
                />
              </div>
            </div>

            <div className="form-group country-code mb-3">
              <label>
                Mobile Number <span className="asterisk">*</span>
              </label>

              <input
                placeholder="Enter your contact"
                name="number"
                className="p-inputtext p-component"
              />
            </div>
            <div className="form-group grid-span-full mb-3">
              <label>
                Message<span className="asterisk">*</span>
              </label>
              <textarea
                placeholder="Ask your question...."
                rows={8}
                cols={30}
                name="question"
                id="question"
                className="p-inputtextarea p-inputtext p-component"
                defaultValue={""}
              />
            </div>
          </div>
          <div className="form-button-group">
            <button type="submit" className="btn btn-primary">
              Submit
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default AskQuestion;

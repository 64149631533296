import React from "react";
import { MdCall, MdEmail } from "react-icons/md";


import flagEnglish from "../../../assets/images/flag-english.png"
import flagSpain from "../../../assets/images/flag-spain.png"
import flagRussian from "../../../assets/images/flag-russian.png"
import flagFrench from "../../../assets/images/flag-french.png"


function TopHeader() {
  return (
    <>
      <div className="tm-header-toparea bg-black">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-8 col-12">
              <ul className="tm-header-info">
                <li>
                  <a href="tel:18883456789">
                    <MdCall />
                    1-888-345-6789
                  </a>
                </li>
                <li>
                  <a href="mailto:contact@example.com">
                    <MdEmail />
                    contact@example.com
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-lg-4 col-12">
              <div className="tm-header-options">
                <div className="tm-dropdown tm-header-links">
                  <button>My Account</button>
                  <ul>
                    <li>
                      <a href="#">My Account</a>
                    </li>
                    <li>
                      <a href="#">Login/Register</a>
                    </li>
                    <li>
                      <a href="#">Shopping Cart</a>
                    </li>
                    <li>
                      <a href="#">Wishlist</a>
                    </li>
                    <li>
                      <a href="#">Checkout</a>
                    </li>
                  </ul>
                </div>
                <div className="tm-dropdown tm-header-currency">
                  <button>USD</button>
                  <ul>
                    <li>
                      <a href="#">USD</a>
                    </li>
                    <li>
                      <a href="#">EUR</a>
                    </li>
                    <li>
                      <a href="#">JPY</a>
                    </li>
                    <li>
                      <a href="#">GBP</a>
                    </li>
                  </ul>
                </div>
                <div className="tm-dropdown tm-header-language">
                  <button>
                    <img src={flagEnglish} alt="language" />
                    English
                  </button>
                  <ul>
                    <li>
                      <a href="#">
                        <img
                          src={flagEnglish}
                          alt="language"
                        />
                        English
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <img
                          src={flagSpain}
                          alt="language"
                        />
                        Spanish
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <img
                          src={flagRussian}
                          alt="language"
                        />
                        Russian
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <img
                          src={flagFrench}
                          alt="language"
                        />
                        French
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TopHeader;

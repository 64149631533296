import React from "react";
import { Link } from "react-router-dom";
import Product from "./product/Product";

function Products() {
  return (
    <>
      <section>
        <div
          id="tm-popular-products-area"
          className="tm-section tm-popular-products-area tm-padding-section bg-white"
        >
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-6 col-12">
                <div className="tm-sectiontitle text-center">
                  <h3>POPULAR PRODUCTS</h3>
                  <p>
                    Our popular products are so beautyful to see that the
                    shoppers are easily attracted to them.
                  </p>
                </div>
              </div>
            </div>
            <Product />
            
          </div>
        </div>
      </section>
    </>
  );
}

export default Products;

import BlogAside from "./blog-aside/BlogAside";
import BlogBody from "./blog-body/BlogBody";

function Blog(){
  return (
    <>
      <main className="page-content">
        <div className="tm-section tm-blogs-page tm-padding-section bg-white">
          <div className="container">
            <div className="row">
              <BlogBody/>
              <BlogAside/>
            </div>
          </div>
        </div>
        

      </main>
    </>
  )
}
export default Blog;
import React from "react";
import About from "../../components/about/About";

function AboutPage() {
  return (
    <>
      <About />
    </>
  );
}

export default AboutPage;

import React from "react";

function AddReview() {
  return (
    <>
      <h5>Add a review</h5>
      <form action="#" className="tm-form">
        <div className="tm-form-inner">
          <div className="tm-form-field">
            <div className="tm-ratingbox tm-ratingbox-input">
              <span className="is-active">
                <i className="ion-android-star-outline"></i>
              </span>
              <span className="is-active">
                <i className="ion-android-star-outline"></i>
              </span>
              <span className="is-active">
                <i className="ion-android-star-outline"></i>
              </span>
              <span className="is-active">
                <i className="ion-android-star-outline"></i>
              </span>
              <span className="is-active">
                <i className="ion-android-star-outline"></i>
              </span> 
            </div>
          </div>
          <div className="tm-form-field tm-form-fieldhalf">
            <input type="text" placeholder="Your Name*" required="required"  />
          </div>
          <div className="tm-form-field tm-form-fieldhalf">
            <input type="Email" placeholder="Your Email*" required="required" />
          </div>
          <div className="tm-form-field">
            <textarea
              name="product-review"
              cols="30"
              rows="5"
              placeholder="Your Review"
            ></textarea>
          </div>
          <div className="tm-form-field">
            <button type="button" className="tm-button">
              Submit
            </button>
          </div>
        </div>
      </form>
    </>
  );
}

export default AddReview;

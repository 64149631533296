
import { RiPinterestLine } from "react-icons/ri";
import { AiOutlineSkype } from "react-icons/ai";
import { TiSocialInstagram } from "react-icons/ti";
import { GrFacebookOption } from "react-icons/gr";
import memberFour from "../../assets/images/team-member-4.jpg";
import memberThree from "../../assets/images/team-member-3.jpg";
import memberTwo from "../../assets/images/team-member-2.jpg";
import memberOne from "../../assets/images/team-member-1.jpg";
import aboutImage from "../../assets/images/about-image.jpg";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import Breadcrumbs from "../../shared/breadcrumbs/Breadcrumbs";
function About() {
  const options = {
    responsiveClass: true,
    loop: true,
    dots: false,
    nav: true,
    margin: 30,
    responsive: {
      0: {
        items: 1,
      },
      390: {
        items: 1,
      },
      768:{
        items:2,
      },
      820:{
        items:3,
      },
      1200: {
        items: 4,
      },
    },
  };

  return (
    <>

      <Breadcrumbs title="About" />
      <div className="tm-about-area tm-padding-section bg-white">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="tm-about-image">
                <img src={aboutImage} />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="tm-about-content">
                <h4>WELCOME TO KiaraJewellers STORE</h4>
                <h6>Lorem ipsum dolor sit amet consectetur adipisicing elitsed do
                  eiusmod ncididunt ametfh consectetur.</h6>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                  incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quisnos trud
                  exercitation ullamco laboris nisi ut aliquip ex ea com modo consequat. Duis aute
                  irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                  pariatur.</p>
                <p>Excepteur sint occaecat cupidatat non proident.sunt in culpa qui officia deserunt
                  mollit anim id est laborum consectetur adipiscing elit, sed do eiusmod tempor incid
                  idunt ut labore et dolore magna aliqua.</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="tm-team-members tm-padding-section bg-grey">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6 col-12">
              <div className="tm-sectiontitle text-center">
                <h3>MEET OUR TEAM</h3>
                <p>Lorem ipsum dolor sit amet consectetur adipiscing elit. Sed aci erat dales vitakse
                  dalesnon estin vitae egestas.</p>
              </div>
            </div>
          </div>

          <div className="row tm-member-slider">
            <OwlCarousel className='owl-theme' {...options} >
              <div className="item">
                <div className="col-12">
                  <div className="tm-member">
                    <div className="tm-member-topside">
                      <img src={memberOne} alt="team member" />
                      <ul>
                        <li><a href="#">< GrFacebookOption className="ion-social-facebook" /></a></li>
                        <li><a href="#"><TiSocialInstagram className="ion-social-instagram-outline" /></a></li>
                        <li><a href="#"><AiOutlineSkype className="ion-social-skype-outline" /></a></li>
                        <li><a href="#"><RiPinterestLine className="ion-social-pinterest-outline" /></a></li>
                      </ul>
                    </div>
                    <div className="tm-member-bottomside">
                      <h6>Henry Todd</h6>
                      <p>Founder & CEO</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="col-12">
                  <div className="tm-member">
                    <div className="tm-member-topside">
                      <img src={memberTwo} alt="team member" />
                      <ul>
                        <li><a href="#">< GrFacebookOption className="ion-social-facebook" /></a></li>
                        <li><a href="#"><TiSocialInstagram className="ion-social-instagram-outline" /></a></li>
                        <li><a href="#"><AiOutlineSkype className="ion-social-skype-outline" /></a></li>
                        <li><a href="#"><RiPinterestLine className="ion-social-pinterest-outline" /></a></li>
                      </ul>
                    </div>
                    <div className="tm-member-bottomside">
                      <h6>Jamie McGuirk</h6>
                      <p>Managing Director</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="col-12">
                  <div className="tm-member">
                    <div className="tm-member-topside">
                      <img src={memberThree} alt="team member" />
                      <ul>
                        <li><a href="#">< GrFacebookOption className="ion-social-facebook" /></a></li>
                        <li><a href="#"><TiSocialInstagram className="ion-social-instagram-outline" /></a></li>
                        <li><a href="#"><AiOutlineSkype className="ion-social-skype-outline" /></a></li>
                        <li><a href="#"><RiPinterestLine className="ion-social-pinterest-outline" /></a></li>
                      </ul>
                    </div>
                    <div className="tm-member-bottomside">
                      <h6>Sebastian Goudie</h6>
                      <p>Sales Director</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="col-12">
                  <div className="tm-member">
                    <div className="tm-member-topside">
                      <img src={memberFour} alt="team member" />
                      <ul>
                        <li><a href="#">< GrFacebookOption className="ion-social-facebook" /></a></li>
                        <li><a href="#"><TiSocialInstagram className="ion-social-instagram-outline" /></a></li>
                        <li><a href="#"><AiOutlineSkype className="ion-social-skype-outline" /></a></li>
                        <li><a href="#"><RiPinterestLine className="ion-social-pinterest-outline" /></a></li>
                      </ul>
                    </div>
                    <div className="tm-member-bottomside">
                      <h6>Bailey Beggs</h6>
                      <p>Support Guru</p>
                    </div>
                  </div>
                </div>
              </div>
            </OwlCarousel>
          </div>
        </div>
      </div>
    </>
  )
}
export default About;
import React from "react";
import WidgetCategories from "./widget-categories/WidgetCategories";
import WidgetPopularProduct from "./widget-popularproduct/WidgetPopularProduct";
import WidgetPricefilter from "./widget-pricefilter/WidgetPricefilter";

function Widgets() {
  return (
    <>
      <div className="widgets">
        <WidgetCategories />
        <WidgetPricefilter />
        <WidgetPopularProduct />
      </div>
    </>
  );
}

export default Widgets;

import React from 'react';
import shopping from  "../../../../assets/images/icons/icon-free-shipping.png";
import delivery from  "../../../../assets/images/icons/icon-fast-delivery.png";
import support from  "../../../../assets/images/icons/icon-247-support.png";

function FeatureItem() {
  return (
    <>
        {/* Single Feature */}
        <div className="col-lg-4 mt-30">
              <div className="tm-feature">
                <span className="tm-feature-icon">
                  <img
                    src={shopping}
                    alt="free shipping"
                  />
                </span>
                <div className="tm-feature-content">
                  <h6>Free Shipping</h6>
                  <p>We provide free shipping for all order over INR 200.00</p>
                </div>
              </div>
            </div>
            {/* Single Feature */}

            {/* Single Feature */}
            <div className="col-lg-4 mt-30">
              <div className="tm-feature">
                <span className="tm-feature-icon">
                  <img
                    src={delivery}
                    alt="fast delivery"
                  />
                </span>
                <div className="tm-feature-content">
                  <h6>Fast Delivery</h6>
                  <p>We always deliver our customers very quickly.</p>
                </div>
              </div>
            </div>
            {/* Single Feature */}

            {/* Single Feature */}
            <div className="col-lg-4 mt-30">
              <div className="tm-feature">
                <span className="tm-feature-icon">
                  <img
                    src={support}
                    alt="24/7 Support"
                  />
                </span>
                <div className="tm-feature-content">
                  <h6>24/7 Support</h6>
                  <p>We provide support to our customers within 24 hours. </p>
                </div>
              </div>
            </div>
            {/* Single Feature */}
    </>
  )
}

export default FeatureItem
import React, { useState } from "react";
import ShopHeader from "../../prodcuts/main-products/shop-header/ShopHeader";
import ShopProductsColumn from "./shop-products-column/ShopProductsColumn";

function ProductsFullItems() {
  const [isActive, setIsActive] = useState(false);
  const [isClass, setIsIsClass] = useState(false);
  const handleActive = () => {
    setIsActive(!isActive);
    setIsIsClass(!isClass);
  };
  return (
    <>
      <ShopHeader handleActive={handleActive} isActive={isActive} />
      <ShopProductsColumn isClass={isClass} />
    </>
  );
}

export default ProductsFullItems;

import React from 'react'
import BlogsItem from './blogs-item/BlogsItem'

function Blogs() {
  return (
    <>
        {/* Latest Blogs Area */}
            <div id="tm-news-area" className="tm-section tm-blog-area tm-padding-section bg-pattern-transparent">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-6 col-12">
                            <div className="tm-sectiontitle text-center">
                                <h3>LATEST BLOGS</h3>
                                <p>A blog is a discussion or informational website published on the World Wide Web
                                    consisting of discrete</p>
                            </div>
                        </div>
                    </div>
                    <BlogsItem />
                </div>
            </div>
            {/* Latest Blogs Area */}
    </>
  )
}

export default Blogs
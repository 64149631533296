import React from "react";

function WidgetPricefilter() {
  return (
    <>
      <div className="single-widget widget-pricefilter">
        <h6 className="widget-title">Filter by Price</h6>
        <div className="widget-pricefilter-inner">
          <div
            className="tm-rangeslider"
            data-range_min="0"
            data-range_max="800"
            data-cur_min="200"
            data-cur_max="550"
          >
            <div
              className="tm-rangeslider-bar nst-animating"
            ></div>
            <span
              className="tm-rangeslider-leftgrip nst-animating"
              tabIndex="0"
            ></span>
            <span
              className="tm-rangeslider-rightgrip nst-animating"
              tabIndex="0"
            ></span>
          </div>
          <div className="widget-pricefilter-actions">
            <p className="widget-pricefilter-price">
              Price: $<span className="tm-rangeslider-leftlabel">200</span>- $
              <span className="tm-rangeslider-rightlabel">550</span>
            </p>
            <button className="widget-pricefilter-button">Filter</button>
          </div>
        </div>
      </div>
    </>
  );
}

export default WidgetPricefilter;

import React from "react";
import Breadcrumbs from "../../shared/breadcrumbs/Breadcrumbs";
import Widgets from "../prodcuts/widgets/Widgets";
import ProductDescription from "./product-description/ProductDescription";
import ProductDetailsContent from "./product-details-content/ProductDetailsContent";
import ProductDetailsImage from "./product-details-image/ProductDetailsImage";
import ProductDetailsReview from "./product-details-review/ProductDetailsReview";
import SimilarProducts from "./similar-products/SimilarProducts";
import AskQuestion from "./ask-question/AskQuestion";

function ProductDetails() {
  return (
    <>
      <Breadcrumbs title="Stylist Diamond Ring" />
      <main className="tm-product-details-area tm-section tm-padding-section bg-white">
        <div className="container">
          <div className="row">
            <div className="col-lg-5">
              <div className="tm-prodetails">
                <ProductDetailsImage />
              </div>
            </div>
            <div className="col-lg-7">
              {/* <Widgets /> */}
              <div className="tm-prodetails">
                <ProductDetailsContent />
              </div>
            </div>
            <div className="col-lg-12">
              <div className="tm-prodetails-desreview tm-padding-section-sm-top">
                <ul className="nav tm-tabgroup2" id="myTab" role="tablist">
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link active"
                      id="home-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#home-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="home-tab-pane"
                      aria-selected="true"
                    >
                      Description
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="profile-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#profile-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="profile-tab-pane"
                      aria-selected="false"
                    >
                      Reviews
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="ask-question-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#ask-question-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="ask-question-tab-pane"
                      aria-selected="false"
                    >
                      Ask a Question
                    </button>
                  </li>
                </ul>
                <div className="tab-content" id="myTabContent">
                  <div
                    className="tab-pane fade show active"
                    id="home-tab-pane"
                    role="tabpanel"
                    aria-labelledby="home-tab"
                    tabIndex="0"
                  >
                    <ProductDescription />
                  </div>
                  <div
                    className="tab-pane fade"
                    id="profile-tab-pane"
                    role="tabpanel"
                    aria-labelledby="profile-tab"
                    tabIndex="0"
                  >
                    <ProductDetailsReview />
                  </div>

                  <div
                    className="tab-pane fade"
                    id="ask-question-tab-pane"
                    role="tabpanel"
                    aria-labelledby="ask-question-tab"
                    tabIndex="0"
                  >
                   <AskQuestion />
                  </div>

                </div>
              </div>
            </div>
            <SimilarProducts />
          </div>
        </div>
      </main>
    </>
  );
}

export default ProductDetails;

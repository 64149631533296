import React from "react";
import { Link } from "react-router-dom";
import "./Menu.css";
function Menu() {
  return (
    <>
      <div className="tm-header-bottomarea bg-white">
        <div className="container">
          <nav className="tm-header-nav">
            <ul className="mainNav">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/about">About</Link>
              </li>
              <li className="tm-header-nav-dropdown">
                <a href="#">Shop</a>
                <ul>
                  <li>
                    <Link to="/products">Products</Link>
                  </li>
                  <li>
                    <a href="#">Products Left Sidebar</a>
                  </li>
                  <li>
                    <a href="#">Products Without Sidebar</a>
                  </li>
                  <li>
                    <a href="#">Products 4 Column</a>
                  </li>
                  <li>
                    <a href="#">Product Details</a>
                  </li>
                  <li>
                    <a href="#">Product Details Left Sidebar</a>
                  </li>
                  <li>
                    <a href="#">Product Details Without Sidebar</a>
                  </li>
                  <li className="has-child">
                    <a href="#">Others</a>
                    <ul>
                      <li>
                        <a href="#">Shopping Cart</a>
                      </li>
                      <li>
                        <a href="#">Wishlist</a>
                      </li>
                      <li>
                        <a href="#">Checkout</a>
                      </li>
                      <li>
                        <a href="#">My Account</a>
                      </li>
                      <li>
                        <a href="#">Login / Register</a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li className="tm-header-nav-megamenu overflow-element">
                <a href="#">Pages</a>

                <ul>
                  <li>
                    <a href="#">Common Pages</a>
                    <ul>
                      <li>
                        <a href="#">Homepage</a>
                      </li>
                      <li>
                        <a href="#">About</a>
                      </li>
                      <li>
                        <Link to="/portfolios">Portfolios</Link>
                      </li>
                      <li>
                        <a href="#">Contact</a>
                      </li>
                    </ul>
                  </li>
                  <li>
                  <Link to="/blog">Blog</Link>
                    <ul>
                  <li>
                    <Link to="/blog">Blog</Link>
                  </li>
                  <li>
                    <Link to="/blog-left-sidebar">Blog Left Sidebar</Link>
                  </li>
                  <li>
                    <Link to="/blog-details">Blog Details</Link>
                  </li>
                  <li>
                    <Link to="/blog-details-left-sidebar">Blog Details Left Sidebar</Link>
                  </li>
                </ul>
                  </li>
                  <li>
                    <a href="#">Shop Pages</a>
                    <ul>
                      <li>
                        <Link to="/products">Products</Link>
                      </li>
                      <li>
                        <Link to="/products-leftsidebar">
                          Products Left Sidebar
                        </Link>
                      </li>
                      <li>
                        <Link to="/products-nosidebar">
                          Products Without Sidebar
                        </Link>
                      </li>
                      <li>
                        <Link to="/products-4-column">products-4-column</Link>
                      </li>
                      <li>
                        <Link to="/product-details">Product Details</Link>
                      </li>
                      <li>
                        <Link to="/product-details-leftsidebar">
                          Product Details Left Sidebar
                        </Link>
                      </li>
                      <li>
                        <Link to="/product-details-nosidebar">
                          Product Details Without Sidebar
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a href="#">Shop Related Pages</a>
                    <ul>
                      <li>
                        <Link to="/cart">Shopping Cart</Link>
                      </li>
                      <li>
                        <Link to="/wishlist">Wishlist</Link>
                      </li>
                      <li>
                        <Link to="/checkout">Checkout</Link>
                      </li>
                      <li>
                        <Link to="/account">Account</Link>
                      </li>
                      <li>
                        <Link to="/login-register">Login / Register</Link>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li className="tm-header-nav-dropdown">
                <Link to="/blog">Blog</Link>

                <ul>
                  <li>
                    <Link to="/blog">Blog</Link>
                  </li>
                  <li>
                    <Link to="/blog-left-sidebar">Blog Left Sidebar</Link>
                  </li>
                  <li>
                    <Link to="/blog-details">Blog Details</Link>
                  </li>
                  <li>
                    <Link to="/blog-details-left-sidebar">Blog Details Left Sidebar</Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to="/contact">Contact</Link>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </>
  );
}

export default Menu;

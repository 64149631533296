import React from "react";
import Portfolios from "../../components/portfolios/Portfolios";

function PortfolioPage() {
  return (
    <>
      <Portfolios />
    </>
  );
}

export default PortfolioPage;

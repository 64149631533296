import React from "react";
import Form from "react-bootstrap/Form";
import "./ShopHeader.css";
function ShopHeader(props) {
  return (
    <>
      <form action="#" className="tm-shop-header shopHeader">
        <div className="tm-shop-productview ">
          <span>View:</span>
          <button
            data-view="grid"
            type="button"
            onClick={props.handleActive}
            className={props.isActive ? "" : "active"}
          >
            <i className="ion-android-apps"></i>
          </button>
          <button
            data-view="list"
            type="button"
            onClick={props.handleActive}
            className={props.isActive ? "active" : ""}
          >
            <i className="ion-android-menu"></i>
          </button>
        </div>
        <p className="tm-shop-countview">Showing 1 to 9 of 16 </p>

        <div className="">
          <Form.Select
            aria-label="Default select example"
            className="niceSelect"
          >
            <option data-value="Default Sorting" className="option">
              Default Sorting
            </option>
            <option data-value="Name A-Z" className="option">
              Name A-Z
            </option>
            <option data-value="Date" className="option">
              Date
            </option>
            <option data-value="Best Sellers" className="option">
              Best Sellers
            </option>
            <option data-value="Trending" className="option">
              Trending
            </option>
          </Form.Select>
        </div>
      </form>
    </>
  );
}

export default ShopHeader;

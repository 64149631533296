import React from "react";
import BackToTop from "react-back-to-top-button";
import "./BackTop.css"

function BackTop() {
  return (
    <>
      <BackToTop
        showOnScrollUp
        showAt={100}
        speed={1500}
        easing="easeInOutQuint"
      >
        <button id="back-top-top"><i class="ion-arrow-up-c"></i></button>
      </BackToTop>
    </>
  );
}

export default BackTop;

import React from "react";
import ArrivalProduct from "./arrival-product/ArrivalProduct";

function ArrivalProducts() {
  return (
    <>
      {/* Popular Products Area */}
      <div
        id="tm-latest-products-area"
        className="tm-section tm-latest-products-area tm-padding-section bg-white"
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6 col-12">
              <div className="tm-sectiontitle text-center">
                <h3>NEW ARRIVAL PRODUCTS</h3>
                <p>
                  Our popular products are so beautyful to see that the shoppers
                  are easily attracted to them.
                </p>
              </div>
            </div>
          </div>
          <ArrivalProduct />
          {/* <div className="d-flex justify-content-center">
              <button type="button" className="btn btn-primary text-center text-uppercase">
                Coming Soon...
              </button>
            </div> */}
          <div className="tm-product-loadmore text-center mt-50">
            <a href="#" className="tm-button">
              All Products
            </a>
          </div>
        </div>
      </div>
      {/* Popular Products Area */}
    </>
  );
}

export default ArrivalProducts;

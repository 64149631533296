import React from "react";
import ShoppingCart from "../../components/shopping-cart/ShoppingCart";
import Breadcrumbs from "../../shared/breadcrumbs/Breadcrumbs";

function CartPage() {
  return (
    <>
      <Breadcrumbs title="Shopping Cart" />
      <ShoppingCart />
    </>
  );
}

export default CartPage;

import React from "react";
import blogImg1 from "../../../../assets/images/blog/blog-image-1.jpg";
import blogImg2 from "../../../../assets/images/blog/blog-image-2.jpg";
import blogImg3 from "../../../../assets/images/blog/blog-image-3.jpg";
import blogImg4 from "../../../../assets/images/blog/blog-image-4.jpg";
import blogImg5 from "../../../../assets/images/blog/blog-image-5.jpg";

import { FaUserAlt, FaComment } from "react-icons/fa";
import OwlCarousel from "react-owl-carousel";

function BlogsItem() {
  return (
    <>
      <OwlCarousel
        className="tm-blog-slider owl-theme"
        loop
        margin={30}
        items={3}
        autoplayTimeout={3000}
        autoplay
        dots={false}
      >
        {/* Blog Single Item */}
        <div className="item">
          <div className="tm-blog  ">
            <div className="tm-blog-topside">
              <div className="tm-blog-thumb">
                <img src={blogImg1} alt="blog image" />
              </div>
              <span className="tm-blog-metahighlight">
                <span>Apr</span>17
              </span>
            </div>
            <div className="tm-blog-content">
              <h6 className="tm-blog-title">
                <a href="#">Woman wearing gold-colore ring pendant necklaces</a>
              </h6>
              <ul className="tm-blog-meta">
                <li>
                  <a href="">
                    <FaUserAlt /> Anderson
                  </a>
                </li>
                <li>
                  <a href="">
                    <FaComment /> 3 Comments
                  </a>
                </li>
              </ul>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incdidunt ut labore et dolore magna aliqua [....]
              </p>
              <a href="#" className="tm-readmore">
                Read more
              </a>
            </div>
          </div>
        </div>
        {/* Blog Single Item */}

        {/* Blog Single Item */}
        <div className="item">
          <div className="tm-blog">
            <div className="tm-blog-topside">
              <div className="tm-blog-thumb">
                <img src={blogImg2} alt="blog image" />
              </div>
              <span className="tm-blog-metahighlight">
                <span>Apr</span>17
              </span>
            </div>
            <div className="tm-blog-content">
              <h6 className="tm-blog-title">
                <a href="#">
                  Shallow focus photo of person putting gold-colored ring
                </a>
              </h6>
              <ul className="tm-blog-meta">
                <li>
                  <a href="">
                    <FaUserAlt /> Anderson
                  </a>
                </li>
                <li>
                  <a href="">
                    <FaComment /> 3 Comments
                  </a>
                </li>
              </ul>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incdidunt ut labore et dolore magna aliqua [....]
              </p>
              <a href="#" className="tm-readmore">
                Read more
              </a>
            </div>
          </div>
        </div>
        {/* Blog Single Item */}

        {/* Blog Single Item */}
        <div className="item">
          <div className="tm-blog  ">
            <div className="tm-blog-topside">
              <div className="tm-blog-thumb">
                <img src={blogImg3} alt="blog image" />
              </div>
              <span className="tm-blog-metahighlight">
                <span>Apr</span>17
              </span>
            </div>
            <div className="tm-blog-content">
              <h6 className="tm-blog-title">
                <a href="#">Silver-colored tiara rings with clear gemstones</a>
              </h6>
              <ul className="tm-blog-meta">
                <li>
                  <a href="">
                    <FaUserAlt /> Anderson
                  </a>
                </li>
                <li>
                  <a href="">
                    <FaComment /> 3 Comments
                  </a>
                </li>
              </ul>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incdidunt ut labore et dolore magna aliqua [....]
              </p>
              <a href="#" className="tm-readmore">
                Read more
              </a>
            </div>
          </div>
        </div>
        {/* Blog Single Item */}

        {/* Blog Single Item */}
        <div className="item">
          <div className="tm-blog  ">
            <div className="tm-blog-topside">
              <div className="tm-blog-thumb">
                <img src={blogImg4} alt="blog image" />
              </div>
              <span className="tm-blog-metahighlight">
                <span>Apr</span>17
              </span>
            </div>
            <div className="tm-blog-content">
              <h6 className="tm-blog-title">
                <a href="#">
                  Diamond ring is worn on top of the engagement band
                </a>
              </h6>
              <ul className="tm-blog-meta">
                <li>
                  <a href="">
                    <FaUserAlt /> Anderson
                  </a>
                </li>
                <li>
                  <a href="">
                    <FaComment /> 3 Comments
                  </a>
                </li>
              </ul>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incdidunt ut labore et dolore magna aliqua [....]
              </p>
              <a href="#" className="tm-readmore">
                Read more
              </a>
            </div>
          </div>
        </div>
        {/* Blog Single Item */}

        {/* Blog Single Item */}
        <div className="item">
          <div className="tm-blog  ">
            <div className="tm-blog-topside">
              <div className="tm-blog-thumb">
                <img src={blogImg5} alt="blog image" />
              </div>
              <span className="tm-blog-metahighlight">
                <span>Apr</span>17
              </span>
            </div>
            <div className="tm-blog-content">
              <h6 className="tm-blog-title">
                <a href="#">White gold engagement rings for couples</a>
              </h6>
              <ul className="tm-blog-meta">
                <li>
                  <a href="">
                    <FaUserAlt /> Anderson
                  </a>
                </li>
                <li>
                  <a href="">
                    <FaComment /> 3 Comments
                  </a>
                </li>
              </ul>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incdidunt ut labore et dolore magna aliqua [....]
              </p>
              <a href="#" className="tm-readmore">
                Read more
              </a>
            </div>
          </div>
        </div>
        {/* Blog Single Item  */}
      </OwlCarousel>
    </>
  );
}

export default BlogsItem;

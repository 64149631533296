import React from "react";

function OfferCountDown() {
  return (
    <>
      <div className="tm-countdown" data-countdown="2020/10/12">
        <div className="tm-countdown-pack tm-countdown-day">
          <h2 className="tm-countdown-count">0</h2>
          <h6>Days</h6>
        </div>
        <div className="tm-countdown-pack tm-countdown-hour">
          <h2 className="tm-countdown-count">0</h2>
          <h6>Hours</h6>
        </div>
        <div className="tm-countdown-pack tm-countdown-minutes">
          <h2 className="tm-countdown-count">00</h2>
          <h6>Minutes</h6>
        </div>
        <div className="tm-countdown-pack tm-countdown-seconds">
          <h2 className="tm-countdown-count">00</h2>
          <h6>Seconds</h6>
        </div>
      </div>
      <a href="product-details.html" className="tm-button">
        Shop now
      </a>
    </>
  );
}

export default OfferCountDown;

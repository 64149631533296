import React from "react";
import {BsHeart, BsHandbag} from "react-icons/bs"
import {AiOutlineSearch} from "react-icons/ai"
import kiaraLogo from "../../../assets/images/logo.png"
import { Link } from "react-router-dom";

function MiddleHeader() {
  return (
    <>
      <div className="tm-header-middlearea bg-white">
        <div className="container">
          <div className="tm-mobilenav"></div>
          <div className="row align-items-center">
            <div className="col-lg-3 col-6 order-1 order-lg-1">
              <Link to="/" className="tm-header-logo search_logo">
                <img src={kiaraLogo} alt="KiaraJewellers" />
              </Link>
            </div>
            <div className="col-lg-6 col-12 order-3 order-lg-2">
              <form className="tm-header-search">
                <input type="text" placeholder="Search product..." />
                <button>
                  <AiOutlineSearch />
                </button>
              </form>
            </div>
            <div className="col-lg-3 col-6 order-2 order-lg-3">
              <ul className="tm-header-icons">
                <li>
                  <a href="#">
                    <BsHeart />
                    <span>0</span>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <BsHandbag />
                    <span>0</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MiddleHeader;

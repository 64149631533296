import React from "react";
import { Link } from "react-router-dom";

import footerLogo from "../../assets/images/logo.png"
import CopyRights from "./copy-right/CopyRights";

function Footer() {
  return (
    <>
      <div className="tm-footer-toparea tm-padding-section">
        <div className="container">
          <div className="widgets widgets-footer row">
            {/* Single Widget */}
            <div className="col-lg-3 col-md-6 col-12">
              <div className="single-widget widget-info">
                <a className="widget-info-logo" href="#">
                  <img src={footerLogo} alt="logo" />
                </a>
                <p>Lorem ipsum dolor sit amet, consect etur adipiscing elit.</p>
                <ul>
                  <li>
                    <b>Address :</b>2726 Avenue Papineau Montreal, QC, Canada
                  </li>
                  <li>
                    <b>Phone :</b>
                    <a href="tel:+18009156270">1-800-915-6270</a>
                  </li>
                  <li>
                    <b>Email :</b>
                    <a href="mailto:info@example.com">info@example.com</a>
                  </li>
                </ul>
              </div>
            </div>
            {/* Single Widget */}

            {/* Single Widget */}
            <div className="col-lg-3 col-md-6 col-12">
              <div className="single-widget widget-quicklinks">
                <h6 className="widget-title">Useful Link</h6>
                <ul>
                  <li>
                    <Link to="/about">About Us</Link>
                  </li>
                  <li>
                    <a href="#">Delivery Info</a>
                  </li>
                  <li>
                    <a href="#">Privacy &amp; Policy</a>
                  </li>
                  <li>
                    <a href="#">Returns &amp; Refunds</a>
                  </li>
                  <li>
                    <a href="#">Terms &amp; Conditions</a>
                  </li>
                  <li>
                    <Link to="/contact">Contact Us</Link>

                  </li>
                </ul>
              </div>
            </div>
            {/* Single Widget */}

            {/* Single Widget */}
            <div className="col-lg-3 col-md-6 col-12">
              <div className="single-widget widget-quicklinks">
                <h6 className="widget-title">My Account</h6>
                <ul>
                  <li>
                    <a href="#">My account</a>
                  </li>
                  <li>
                    <a href="#">Cart</a>
                  </li>
                  <li>
                    <a href="#">Wishlist</a>
                  </li>
                  <li>
                    <a href="#">Newsletter</a>
                  </li>
                  <li>
                    <a href="#">Check out</a>
                  </li>
                  <li>
                    <a href="#">Frequently Questions</a>
                  </li>
                </ul>
              </div>
            </div>
            {/* Single Widget */}

            {/* Single Widget */}
            <div className="col-lg-3 col-md-6 col-12">
              <div className="single-widget widget-newsletter">
                <h6 className="widget-title">Join Our Newsletter</h6>
                <p>
                  Get Business news, tip and solutions to your problems from our
                  experts.
                </p>
                <form
                  id="tm-mailchimp-form"
                  className="widget-newsletter-form"
                  noValidate={true}
                >
                  <input
                    id="mc-email"
                    type="text"
                    placeholder="Enter email address"
                  />
                  <button id="mc-submit" type="submit" className="tm-button">
                    Subscribe Now
                    <b></b>
                  </button>
                </form>
                {/* Mailchimp Alerts */}
                <div className="tm-mailchimp-alerts">
                  <div className="tm-mailchimp-submitting"></div>
                  <div className="mailchimp-success"></div>
                  <div className="tm-mailchimp-error"></div>
                </div>
                {/* Mailchimp Alerts */}
              </div>
            </div>
            {/* Single Widget  */}
          </div>
        </div>
      </div>
      <CopyRights />
    </>
  );
}

export default Footer;

import React from "react";

import productThumb1 from "../../../../assets/images/products/product-image-1-thumb.jpg";
import productThumb2 from "../../../../assets/images/products/product-image-2-thumb.jpg";
import productThumb3 from "../../../../assets/images/products/product-image-3-thumb.jpg";


function WidgetPopularProduct() {
  return (
    <>
      {/* Single Widge */}
      <div className="single-widget widget-popularproduct">
        <h6 className="widget-title">Popular Product</h6>
        <ul>
          <li>
            <a
              href="product-details.html"
              className="widget-popularproduct-image"
            >
              <img
                src={productThumb1}
                alt="product thumbnail"
              />
            </a>
            <div className="widget-popularproduct-content">
              <h6>
                <a href="product-details.html">Brown liquid inside</a>
              </h6>
              <span>$20.00</span>
            </div>
          </li>
          <li>
            <a
              href="product-details.html"
              className="widget-popularproduct-image"
            >
              <img
                src={productThumb2}
                alt="product thumbnail"
              />
            </a>
            <div className="widget-popularproduct-content">
              <h6>
                <a href="product-details.html">Top of amber bottle</a>
              </h6>
              <span>$35.99</span>
            </div>
          </li>
          <li>
            <a
              href="product-details.html"
              className="widget-popularproduct-image"
            >
              <img
                src={productThumb3}
                alt="product thumbnail"
              />
            </a>
            <div className="widget-popularproduct-content">
              <h6>
                <a href="product-details.html">Mario badescu bottle</a>
              </h6>
              <span>$99.99</span>
            </div>
          </li>
        </ul>
      </div>
      {/* Single Widge */}
    </>
  );
}

export default WidgetPopularProduct;

import React, { useState } from "react";
import ShopHeader from "./shop-header/ShopHeader";
import ShopProducts from "./shop-products/ShopProducts";

function MainProducts() {
  const [isActive, setIsActive] = useState(false);
  const [isClass, setIsIsClass] = useState(false);
    const handleActive = () =>{
        setIsActive(!isActive);
        setIsIsClass(!isClass);
    }
  return (
    <>
      <ShopHeader handleActive={handleActive} isActive={isActive} />
      <ShopProducts isClass={isClass} />
    </>
  );
}

export default MainProducts;

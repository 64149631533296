

function CartTotal() {
  return (
    <>
      <div className="col-lg-4 col-md-6">
        <div className="tm-cart-pricebox">
          <h2>Cart Totals</h2>
          <div className="table-responsive">
            <table className="table table-borderless">
              <tbody>
                <tr className="tm-cart-pricebox-subtotal">
                  <td>Cart Subtotal</td>
                  <td>$175.00</td>
                </tr>
                <tr className="tm-cart-pricebox-shipping">
                  <td>(+) Shipping Charge</td>
                  <td>$15.00</td>
                </tr>
                <tr className="tm-cart-pricebox-total">
                  <td>Total</td>
                  <td>$190.00</td>
                </tr>
              </tbody>
            </table>
          </div>
          <a href="#" className="tm-button">Proceed To Checkout</a>
        </div>
      </div>
    </>
  )
}
export default CartTotal
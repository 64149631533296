import nacklace from "../../assets/images//products/product-image-3-thumb.jpg";
import earring from "../../assets/images/products/product-image-2-thumb.jpg";
import thumb from "../../assets/images/products/product-image-1-thumb.jpg";
import Breadcrumbs from "../../shared/breadcrumbs/Breadcrumbs";
function Wishlist() {
  const handleChange = ()=>{
    alert("helo")
  }
  return (
    <>
    <Breadcrumbs title="Wishlist" />
      <main className="page-content">
        {/* <!-- Wishlist Area --> */}
        <div className="tm-section wishlist-area bg-white tm-padding-section">
          <div className="container">
            {/* <!-- Wishlist Table --> */}
            <div className="tm-wishlist-table table-responsive">
              <table className="table table-bordered mb-0">
                <thead>
                  <tr>
                    <th className="tm-wishlist-col-image" scope="col">Image</th>
                    <th className="tm-wishlist-col-productname" scope="col">Product</th>
                    <th className="tm-wishlist-col-price" scope="col">Price</th>
                    <th className="tm-wishlist-col-quantity" scope="col">Quantity</th>
                    <th className="tm-wishlist-col-addcart" scope="col">Add to Cart</th>
                    <th className="tm-wishlist-col-remove" scope="col">Remove</th>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <td>
                      <a href="product-details.html" className="tm-wishlist-productimage">
                        <img src={thumb} alt="product image" />
                      </a>
                    </td>
                    <td>
                      <a href="product-details.html" className="tm-cart-productname">Stylist
                        daimond
                        earring</a>
                    </td>
                    <td className="tm-wishlist-price">$75.00</td>
                    <td>
                      <div className="tm-quantitybox">
                        <input type="text" defaultValue="1" onChange={handleChange} />
                        <div className="decrement-button tm-quantitybox-button" >-</div>
                        <div className="increment-button tm-quantitybox-button">+</div></div>
                    </td>
                    <td>
                      <a href="#" className="tm-button tm-button-small">Add to Cart</a>
                    </td>
                    <td>
                      <button className="tm-wishlist-removeproduct"><i className="ion-close"></i></button>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <a href="product-details.html" className="tm-wishlist-productimage">
                        <img src={earring} alt="product image" />
                      </a>
                    </td>
                    <td>
                      <a href="product-details.html" className="tm-wishlist-productname">Stylist
                        daimond
                        earring</a>
                    </td>
                    <td className="tm-wishlist-price">$75.00</td>
                    <td>
                      <div className="tm-quantitybox">
                        <input type="text" defaultValue="1" onChange={handleChange} />
                        <div className="decrement-button tm-quantitybox-button">-</div>
                        <div className="increment-button tm-quantitybox-button">+</div></div>
                    </td>
                    <td>
                      <a href="#" className="tm-button tm-button-small">Add to Cart</a>
                    </td>
                    <td>
                      <button className="tm-wishlist-removeproduct"><i className="ion-close"></i></button>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <a href="product-details.html" className="tm-wishlist-productimage">
                        <img src={nacklace} alt="product image" />
                      </a>
                    </td>
                    <td>
                      <a href="product-details.html" className="tm-wishlist-productname">Stylist
                        daimond
                        earring</a>
                    </td>
                    <td className="tm-wishlist-price">$78.00</td>
                    <td>
                      <div className="tm-quantitybox">
                        <input type="text" defaultValue="1"  onChange={handleChange}/>
                        <div className="decrement-button tm-quantitybox-button">-</div>
                        <div className="increment-button tm-quantitybox-button">+</div></div>
                    </td>
                    <td>
                      <a href="#" className="tm-button tm-button-small">Add to Cart</a>
                    </td>
                    <td>
                      <button className="tm-wishlist-removeproduct"><i className="ion-close"></i></button>
                    </td>
                  </tr>
                </tbody>

              </table>
            </div>
            {/* <!--// Wishlist Table --> */}
          </div>
        </div>
        {/* <!--// Wishlist Area --> */}

      </main>

    </>
  )
}
export default Wishlist
import React from "react";
import Breadcrumbs from "../../shared/breadcrumbs/Breadcrumbs";
import ProductsFullItems from "./products-full-items/ProductsFullItems";

function Products4Column() {
  return (
    <>
      <Breadcrumbs title="products" />

      {/* Page Content */}
      <main className="page-content">
        {/* Products Wrapper */}
        <div className="tm-products-area tm-section tm-padding-section bg-white">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-12">
                <ProductsFullItems />
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default Products4Column;


import earring from "../../../../assets/images/products/product-image-2-thumb.jpg";
import thumb from "../../../../assets/images/products/product-image-1-thumb.jpg";

export const ShoppingItemMock = [
  {
    id: "1",
    img: thumb,
    productName: "Stylist daimond ring",
    price: "75",
    product: "ring"
  },
  {
    id: "2",
    img: earring,
    productName: "Stylist daimond earring",
    price: "75",
    product: "earring"
  }

]
import React from "react";
import productImg1 from "../../../assets/images/products/product-image-1.jpg";
import productImg2 from "../../../assets/images/products/product-image-2.jpg";
import productImg3 from "../../../assets/images/products/product-image-3.jpg";
import productImg4 from "../../../assets/images/products/product-image-4.jpg";
import productImg5 from "../../../assets/images/products/product-image-5.jpg";
import productImg6 from "../../../assets/images/products/product-image-6.jpg";

import "./ProductDetailsImage.css";
import Slider from "react-slick";

function ProductDetailsImage() {
    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        arrows: false
      };
  return (
    <>
      <div className="tm-prodetails-images">
        <div className="tm-prodetails-largeimages">
          <div className="tm-prodetails-largeimage">
            <a
              data-fancybox="tm-prodetails-imagegallery"
              href={productImg1}
              data-caption="Product Zoom Image 1"
            >
              <img
                src={productImg1}
                alt="product image"
              />
            </a>
          </div>
          {/* <div className="tm-prodetails-largeimage">
            <a
              data-fancybox="tm-prodetails-imagegallery"
              href={productImg2}
              data-caption="Product Zoom Image 2"
            >
              <img
                src={productImg2}
                alt="product image"
              />
            </a>
          </div>
          <div className="tm-prodetails-largeimage">
            <a
              data-fancybox="tm-prodetails-imagegallery"
              href={productImg3}
              data-caption="Product Zoom Image 3"
            >
              <img
                src={productImg3}
                alt="product image"
              />
            </a>
          </div>
          <div className="tm-prodetails-largeimage">
            <a
              data-fancybox="tm-prodetails-imagegallery"
              href={productImg4}
              data-caption="Product Zoom Image 4"
            >
              <img
                src={productImg4}
                alt="product image"
              />
            </a>
          </div>
          <div className="tm-prodetails-largeimage">
            <a
              data-fancybox="tm-prodetails-imagegallery"
              href={productImg5}
              data-caption="Product Zoom Image 5"
            >
              <img
                src={productImg5}
                alt="product image"
              />
            </a>
          </div>
          <div className="tm-prodetails-largeimage">
            <a
              data-fancybox="tm-prodetails-imagegallery"
              href={productImg6}
              data-caption="Product Zoom Image 6"
            >
              <img
                src={productImg6}
                alt="product image"
              />
            </a>
          </div> */}
        </div>
        <Slider className="tm-prodetails-thumbnails" {...settings}>
          <div className="tm-prodetails-thumbnail">
            <img
              src={productImg1}
              alt="product image"
            />
          </div>
          <div className="tm-prodetails-thumbnail">
            <img
              src={productImg2}
              alt="product image"
            />
          </div>
          <div className="tm-prodetails-thumbnail">
            <img
              src={productImg3}
              alt="product image"
            />
          </div>
          <div className="tm-prodetails-thumbnail">
            <img
              src={productImg4}
              alt="product image"
            />
          </div>
          <div className="tm-prodetails-thumbnail">
            <img
              src={productImg5}
              alt="product image"
            />
          </div>
          <div className="tm-prodetails-thumbnail">
            <img
              src={productImg6}
              alt="product image"
            />
          </div>
        </Slider>
      </div>
    </>
  );
}

export default ProductDetailsImage;

import React from "react";

function WidgetCategories() {
  return (
    <>
      <div className="single-widget widget-categories">
        <h6 className="widget-title">Categories</h6>
        <ul>
          <li>
            <a href="#">Make up</a>
          </li>
          <li>
            <a href="#">Leapstick</a>
          </li>
          <li>
            <a href="#">Face Powder</a>
          </li>
          <li>
            <a href="#">Eyeliner</a>
          </li>
          <li>
            <a href="#">Maskara</a>
          </li>
          <li>
            <a href="#">Foundation</a>
          </li>
        </ul>
      </div>
    </>
  );
}

export default WidgetCategories;

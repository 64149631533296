import React from "react";
import FeatureItem from "./feature-item/FeatureItem";
function Features() {
  return (
    <>
      <div className="tm-section tm-feature-area bg-grey">
        <div className="container">
          <div className="row mt-30-reverse">
            <FeatureItem />
          </div>
        </div>
      </div>
    </>
  );
}

export default Features;

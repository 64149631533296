import CartTotal from "./cart-total/CartTotal";
import ShoppingItem from "./shopping-item/ShoppingItem";

function ShoppingCart() {
  return (
    <>

      <div className="page-content">
        <div className="tm-section shopping-cart-area bg-white tm-padding-section">
          <div className="container">
            <ShoppingItem />
            <div className="tm-cart-bottomarea">
              <div className="row">
                <div className="col-lg-8 col-md-6">
                  <div className="tm-buttongroup">
                    <a href="#" className="tm-button">Continue Shopping</a>
                    <a href="#" className="tm-button">Update Cart</a>
                  </div>
                  <form action="#" className="tm-cart-coupon">
                    <label for="coupon-field">Have a coupon code?</label>
                    <input type="text" id="coupon-field" placeholder="Enter coupon code" required="required"/>
                      <button type="submit" className="tm-button">Submit</button>
                  </form>
                </div>
                <CartTotal/>
              </div>
            </div>
          </div>
        </div>
      </div>
      
    </>
  )
}
export default ShoppingCart;
import React from "react";
import AddReview from "./add-review/AddReview";
import author from "../../../assets/images/about-image.jpg";

function ProductDetailsReview() {
  return (
    <>
      <div className="tm-prodetails-review">
        <h5>1 Review For Stylist daimond ring</h5>
        <div className="tm-comment-wrapper mb-50">
          {/* Comment Single */}
          <div className="tm-comment">
            <div className="tm-comment-thumb">
              <img src={author} alt="author image" />
            </div>
            <div className="tm-comment-content">
              <h6 className="tm-comment-authorname">
                <a href="#">Frida Bins</a>
              </h6>
              <span className="tm-comment-date">
                Wednesday, October 17, 2018 at 4:00PM.
              </span>
              <div className="tm-ratingbox">
                <span className="is-active">
                  <i className="ion-android-star-outline"></i>
                </span>
                <span className="is-active">
                  <i className="ion-android-star-outline"></i>
                </span>
                <span className="is-active">
                  <i className="ion-android-star-outline"></i>
                </span>
                <span className="is-active">
                  <i className="ion-android-star-outline"></i>
                </span>
                <span>
                  <i className="ion-android-star-outline"></i>
                </span>
              </div>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
                eiusmod tempor incididunt ut labore dolore magna aliqua. Ut enim
                ad minim veniam.
              </p>
            </div>
          </div>
          {/* Comment Single */}
        </div>

        <AddReview />
      </div>
    </>
  );
}

export default ProductDetailsReview;

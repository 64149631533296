import React from 'react'
import Checkout from '../../components/checkout/Checkout'
import Breadcrumbs from '../../shared/breadcrumbs/Breadcrumbs'

function CheckoutPage() {
  return (
    <>
        <Breadcrumbs title="Checkout" />
        <Checkout />
    </>
  )
}

export default CheckoutPage
import React from "react";
import OwlCarousel from "react-owl-carousel";

import heosliderImage1 from "../../../assets/images/heoslider-image-1.jpg";
import heosliderImage2 from "../../../assets/images/heoslider-image-2.jpg";

import "./Slider.css";
function Slider() {
  return (
    <>
      <section className="tm-home-slider">
        {/* Heroslider Area */}
        <div className="tm-heroslider-area bg-grey">
          <div className="tm-heroslider-slider" id="slider">
            {/* Heroslider Item */}
            <OwlCarousel
              className="owl-theme"
              loop
              autoplay
              dots={false}
              nav={true}
              items={1}
            >
              {/* Heroslider Item */}
              <div
                className="tm-heroslider"
                style={{ backgroundImage: `url("${heosliderImage1}")` }}
              >
                <div className="container">
                  <div className="row align-items-center">
                    <div className="col-lg-7 col-md-8 col-12">
                      <div className="tm-heroslider-contentwrapper">
                        <div className="tm-heroslider-content slider">
                          <h1>Woman’s Jewellery Collection</h1>
                          <p>
                            Jewellery may be made from a wide range of
                            materials. Jewellery has been made to adorn nearly
                            every body part from hairpins to toe.
                          </p>
                          <a href="products.html" className="tm-button">
                            Shop Now
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Heroslider Item */}
              <div
                className="tm-heroslider"
                style={{ backgroundImage: `url("${heosliderImage2}")` }}
              >
                <div className="container">
                  <div className="row align-items-center">
                    <div className="col-lg-7 col-md-8 col-12">
                      <div className="tm-heroslider-contentwrapper">
                        <div className="tm-heroslider-content slider">
                          <h1>Woman’s Jewellery Collection</h1>
                          <p>
                            Jewellery may be made from a wide range of
                            materials. Jewellery has been made to adorn nearly
                            every body part from hairpins to toe.
                          </p>
                          <a href="products.html" className="tm-button">
                            Shop Now
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Heroslider Item */}
            </OwlCarousel>
          </div>
        </div>
        {/* Heroslider Area */}
      </section>
    </>
  );
}

export default Slider;

import React from 'react';
import logo from "../../assets/images/logo.png";

function Preloader() {
  return (
    <>
        <div className="tm-preloader">
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-lg-6">
                    <div className="tm-preloader-logo">
                        <img src={logo} alt="logo" style={{width: "80px"}}/>
                    </div>
                    <span className="tm-preloader-progress"></span>
                </div>
            </div>
        </div>
        {/* <button className="tm-button tm-button-small">Cancel Preloader</button> */}
    </div>
    </>
  )
}

export default Preloader
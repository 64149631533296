
import 'react-fancybox/lib/fancybox.css'

// here import click img and view large size
import largeOne from "../../assets/images/portfolios/portfolio-image-1.jpg";
import largeTwo from "../../assets/images/portfolios/portfolio-image-2.jpg";
import largeThree from "../../assets/images/portfolios/portfolio-image-3.jpg";
import largeFour from "../../assets/images/portfolios/portfolio-image-4.jpg";
import largeFive from "../../assets/images/portfolios/portfolio-image-5.jpg";
import largeSix from "../../assets/images/portfolios/portfolio-image-6.jpg";
import largeSeven from "../../assets/images/portfolios/portfolio-image-7.jpg";
import largeEight from "../../assets/images/portfolios/portfolio-image-8.jpg";
import largeNine from "../../assets/images/portfolios/portfolio-image-9.jpg";

//here import img tag sec
import imgOne from "../../assets/images/portfolios/portfolio-image-1.jpg";
import imgTwo from "../../assets/images/portfolios/portfolio-image-2.jpg";
import imgThree from "../../assets/images/portfolios/portfolio-image-3.jpg";
import imgFour from "../../assets/images/portfolios/portfolio-image-4.jpg";
import imgFive from "../../assets/images/portfolios/portfolio-image-5.jpg";
import imgSix from "../../assets/images/portfolios/portfolio-image-6.jpg";
import imgSeven from "../../assets/images/portfolios/portfolio-image-7.jpg";
import imgEight from "../../assets/images/portfolios/portfolio-image-8.jpg";
import imgNine from "../../assets/images/portfolios/portfolio-image-9.jpg";
import Breadcrumbs from '../../shared/breadcrumbs/Breadcrumbs';


function Portfolios() {
  
  return (
    <>
    <Breadcrumbs title="Portfolios" />
      <main className="page-content">
        <div className="tm-section tm-portfolios-area tm-padding-section bg-white">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="tm-portfolio-filters text-center">
                  <button data-filter="*" className="is-active">All</button>
                  <button data-filter=".portfolio-cat-jewellery"  type="button">Jewellery</button>
                  <button data-filter=".portfolio-cat-earrings">Earrings</button>
                  <button data-filter=".portfolio-cat-nacklace">Nacklace</button>
                  <button data-filter=".portfolio-cat-bracelet">Bracelet</button>
                </div>
              </div>
            </div>

            <div className="row tm-portfolio-wrapper mt-30-reverse">
              <div className="col-lg-4 col-md-6 col-sm-6 col-12 tm-portfolio-item portfolio-cat-jewellery mt-30">
                <a href={largeOne} className="tm-portfolio"
                  data-fancybox="portfolio-gallery" data-caption="Self makeup at home">
                  <img src={imgOne} alt="portfolio image" />
                </a>
              </div>

              <div className="col-lg-4 col-md-6 col-sm-6 col-12 tm-portfolio-item portfolio-cat-earrings portfolio-cat-nacklace mt-30">
                <a href={largeTwo} className="tm-portfolio"
                  data-fancybox="portfolio-gallery" data-caption="Self makeup at home">
                  <img src={imgTwo} alt="portfolio image" />
                </a>
              </div>

              <div className="col-lg-4 col-md-6 col-sm-6 col-12 tm-portfolio-item portfolio-cat-jewellery mt-30">
                <a href={largeThree} className="tm-portfolio"
                  data-fancybox="portfolio-gallery" data-caption="Self makeup at home">
                  <img src={imgThree} alt="portfolio image" />
                </a>
              </div>

              <div className="col-lg-4 col-md-6 col-sm-6 col-12 tm-portfolio-item portfolio-cat-jewellery portfolio-cat-nacklace mt-30">
                <a href={largeFour} className="tm-portfolio"
                  data-fancybox="portfolio-gallery" data-caption="Self makeup at home">
                  <img src={imgFour} alt="portfolio image" />
                </a>
              </div>

              <div className="col-lg-4 col-md-6 col-sm-6 col-12 tm-portfolio-item portfolio-cat-earrings portfolio-cat-bracelet mt-30">
                <a href={largeFive} className="tm-portfolio"
                  data-fancybox="portfolio-gallery" data-caption="Self makeup at home">
                  <img src={imgFive} alt="portfolio image" />
                </a>
              </div>

              <div className="col-lg-4 col-md-6 col-sm-6 col-12 tm-portfolio-item portfolio-cat-jewellery mt-30">
                <a href={largeSix} className="tm-portfolio"
                  data-fancybox="portfolio-gallery" data-caption="Self makeup at home">
                  <img src={imgSix} alt="portfolio image" />
                </a>
              </div>

              <div className="col-lg-4 col-md-6 col-sm-6 col-12 tm-portfolio-item portfolio-cat-nacklace portfolio-cat-bracelet mt-30">
                <a href={largeSeven} className="tm-portfolio"
                  data-fancybox="portfolio-gallery" data-caption="Self makeup at home">
                  <img src={imgSeven} alt="portfolio image" />
                </a>
              </div>

              <div className="col-lg-4 col-md-6 col-sm-6 col-12 tm-portfolio-item portfolio-cat-jewellery mt-30">
                <a href={largeEight} className="tm-portfolio"
                  data-fancybox="portfolio-gallery" data-caption="Self makeup at home">
                  <img src={imgEight} alt="portfolio image" />
                </a>
              </div>

              <div className="col-lg-4 col-md-6 col-sm-6 col-12 tm-portfolio-item portfolio-cat-earrings portfolio-cat-bracelet mt-30">
                <a href={largeNine} className="tm-portfolio"
                  data-fancybox="portfolio-gallery" data-caption="Self makeup at home">
                  <img src={imgNine} alt="portfolio image" />
                </a>
              </div>

            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="tm-portfolio-loadmore text-center mt-50">
                <button className="tm-button">Load more</button>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  )
}
export default Portfolios;
import React from "react";
import SubProductItem from "./sub-product-item/SubProductItem";

function SubProducts() {
  return (
    <>
      {/* Banners Area */}
      <div className="tm-section tm-banners-area">
        <div className="container">
          <div className="row mt-30-reverse">
            <SubProductItem />
          </div>
        </div>
      </div>
    </>
  );
}

export default SubProducts;

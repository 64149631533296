import { useEffect, useState } from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { ShoppingItemMock } from "./shopping-item-mock/ShoppingItemMock";
let itemId;
function ShoppingItem() {

  const [data, setData] = useState([])
  useEffect(() => {
    setData(ShoppingItemMock)
  }, []);

  // ----modal---sec---
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // ---end----modal---sec
  const [inc, setInc] = useState({
    ring: { count: 1, price: 75, realPrice: 75 },
    earring: { count: 1, price: 75, realPrice: 75 },
    nacklace: { count: 1, price: 78, realPrice: 78 }
  })


  const incHandler = (product) => {
    const clone = { ...inc };
    clone[product].count += 1;
    clone[product].price = clone[product].realPrice * clone[product].count;
    setInc(clone);
  }
  const decHandler = (product) => {
    const clone = { ...inc };
    if (clone[product].count == 1) {
      return
    }
    clone[product].count -= 1;
    clone[product].price = clone[product].realPrice * clone[product].count
    setInc(clone)
  }

  const handler = (e) => {
    console.log(e.target.value);
    const clone = { ...inc }
    // clone.count = e.target.value
    clone[e.target.name].count = e.target.value
    clone[e.target.name].price = e.target.value * clone[e.target.name].realPrice
    setInc(clone)
  }

  const handleShowtem = (id) => {
    handleShow()
    itemId = id
  }

  const deleteItem = () => {
    data.filter((item) => {
      return itemId !== item.id;
    });
    console.log("data---", data);
  }

  return (
    <>
      <div className="tm-cart-table table-responsive">
        <table className="table table-bordered mb-0">
          <thead>
            <tr>
              <th className="tm-cart-col-image" scope="col">Image</th>
              <th className="tm-cart-col-productname" scope="col">Product</th>
              <th className="tm-cart-col-price" scope="col">Price</th>
              <th className="tm-cart-col-quantity" scope="col">Quantity</th>
              <th className="tm-cart-col-total" scope="col">Total</th>
              <th className="tm-cart-col-remove" scope="col">Remove</th>
            </tr>
          </thead>
          <tbody>
            {data && data.map((item) => {
              return <tr key={item.id}>
                <td>
                  <a href="product-details.html" className="tm-cart-productimage">
                    <img src={item.img} alt="product image" />
                  </a>
                </td>
                <td>
                  <a href="product-details.html" className="tm-cart-productname">{item.productName}</a>
                </td>
                <td className="tm-cart-price">$75.00</td>
                <td>
                  <div className="tm-quantitybox">
                    <input type="number" name={item.product} value={inc[item.product].count} onChange={handler} />
                    <div className="decrement-button tm-quantitybox-button" onClick={() => decHandler(item.product)}>-</div>
                    <div className="increment-button tm-quantitybox-button" onClick={() => incHandler(item.product)}>+</div>
                  </div>
                </td>
                <td>
                  <span className="tm-cart-totalprice">${inc[item.product].price}.00</span>
                </td>
                <td>
                  <button className="tm-cart-removeproduct" onClick={() => { handleShowtem(item.id) }}><i className="ion-close"></i></button>
                </td>
              </tr>
            })}
          </tbody>
        </table>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Body>
          <h6>Are you want to sure delete this item</h6>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button  onClick={deleteItem} className="delete_btn">
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
export default ShoppingItem;
import blogImg1  from "../../../assets/images/blog/blog-image-1.jpg"
import blogImg2 from "../../../assets/images/blog/blog-image-2.jpg"
import blogImg3 from "../../../assets/images/blog/blog-image-3.jpg"
import blogImg4 from "../../../assets/images/blog/blog-image-4.jpg"
import blogImg5 from "../../../assets/images/blog/blog-image-5.jpg"
function BlogBody(){
  return (
    <>
      <div className="col-lg-8 col-12">
        <div className="row tm-blog-container mt-50-reverse">


          <div className="col-lg-6 col-md-6 col-12 mt-50">
            <div className="tm-blog tm-scrollanim scrollanim-action">
              <div className="tm-blog-topside">
                <div className="tm-blog-thumb">
                  <img src={blogImg1} alt="blog image" />
                </div>
                <span className="tm-blog-metahighlight"><span>Apr</span>17</span>
              </div>
              <div className="tm-blog-content">
                <h6 className="tm-blog-title"><a href="blog-details.html">Woman wearing
                  gold-colore ring
                  pendant necklaces</a></h6>
                <ul className="tm-blog-meta">
                  <li><a href="blog.html"><i className="ion-android-person"></i> Anderson</a></li>
                  <li><a href="blog-details.html"><i className="ion-chatbubbles"></i> 3 Comments</a></li>
                </ul>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                  eiusmod tempor
                  incdidunt ut labore et dolore magna aliqua [....]</p>
                <a href="#" className="tm-readmore">Read more</a>
              </div>
            </div>
          </div>

          <div className="col-lg-6 col-md-6 col-12 mt-50">
            <div className="tm-blog tm-scrollanim scrollanim-action">
              <div className="tm-blog-topside">
                <div className="tm-blog-thumb">
                  <img src={blogImg2} alt="blog image" />
                </div>
                <span className="tm-blog-metahighlight"><span>Apr</span>17</span>
              </div>
              <div className="tm-blog-content">
                <h6 className="tm-blog-title"><a href="blog-details.html">Shallow focus
                  photo of person
                  putting gold-colored ring</a></h6>
                <ul className="tm-blog-meta">
                  <li><a href="blog.html"><i className="ion-android-person"></i> Anderson</a></li>
                  <li><a href="blog-details.html"><i className="ion-chatbubbles"></i> 3 Comments</a></li>
                </ul>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                  eiusmod tempor
                  incdidunt ut labore et dolore magna aliqua [....]</p>
                <a href="#" className="tm-readmore">Read more</a>
              </div>
            </div>
          </div>

          <div className="col-lg-6 col-md-6 col-12 mt-50">
            <div className="tm-blog tm-scrollanim scrollanim-action">
              <div className="tm-blog-topside">
                <div className="tm-blog-thumb">
                  <img src={blogImg3} alt="blog image" />
                </div>
                <span className="tm-blog-metahighlight"><span>Apr</span>17</span>
              </div>
              <div className="tm-blog-content">
                <h6 className="tm-blog-title"><a href="blog-details.html">Silver-colored
                  tiara rings
                  with clear
                  gemstones</a></h6>
                <ul className="tm-blog-meta">
                  <li><a href="blog.html"><i className="ion-android-person"></i> Anderson</a></li>
                  <li><a href="blog-details.html"><i className="ion-chatbubbles"></i> 3 Comments</a></li>
                </ul>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                  eiusmod tempor
                  incdidunt ut labore et dolore magna aliqua [....]</p>
                <a href="#" className="tm-readmore">Read more</a>
              </div>
            </div>
          </div>

          <div className="col-lg-6 col-md-6 col-12 mt-50">
            <div className="tm-blog tm-scrollanim scrollanim-action">
              <div className="tm-blog-topside">
                <div className="tm-blog-thumb">
                  <img src={blogImg4} alt="blog image" />
                </div>
                <span className="tm-blog-metahighlight"><span>Apr</span>17</span>
              </div>
              <div className="tm-blog-content">
                <h6 className="tm-blog-title"><a href="blog-details.html">Diamond ring is
                  worn on top of
                  the
                  engagement band</a></h6>
                <ul className="tm-blog-meta">
                  <li><a href="blog.html"><i className="ion-android-person"></i> Anderson</a></li>
                  <li><a href="blog-details.html"><i className="ion-chatbubbles"></i> 3 Comments</a></li>
                </ul>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                  eiusmod tempor
                  incdidunt ut labore et dolore magna aliqua [....]</p>
                <a href="#" className="tm-readmore">Read more</a>
              </div>
            </div>
          </div>

          <div className="col-lg-6 col-md-6 col-12 mt-50">
            <div className="tm-blog tm-scrollanim scrollanim-action">
              <div className="tm-blog-topside">
                <div className="tm-blog-thumb">
                  <img src={blogImg5} alt="blog image" />
                </div>
                <span className="tm-blog-metahighlight"><span>Apr</span>17</span>
              </div>
              <div className="tm-blog-content">
                <h6 className="tm-blog-title"><a href="blog-details.html">White gold
                  engagement rings
                  for
                  couples</a></h6>
                <ul className="tm-blog-meta">
                  <li><a href="blog.html"><i className="ion-android-person"></i> Anderson</a></li>
                  <li><a href="blog-details.html"><i className="ion-chatbubbles"></i> 3 Comments</a></li>
                </ul>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                  eiusmod tempor
                  incdidunt ut labore et dolore magna aliqua [....]</p>
                <a href="#" className="tm-readmore">Read more</a>
              </div>
            </div>
          </div>

          <div className="col-lg-6 col-md-6 col-12 mt-50">
            <div className="tm-blog tm-scrollanim scrollanim-action">
              <div className="tm-blog-topside">
                <div className="tm-blog-thumb">
                  <img src="assets/images/blog/blog-image-6.jpg" alt="blog image" />
                </div>
                <span className="tm-blog-metahighlight"><span>Apr</span>17</span>
              </div>
              <div className="tm-blog-content">
                <h6 className="tm-blog-title"><a href="blog-details.html">Woman wearing all kind
                  of gold
                  colore jewelery</a></h6>
                <ul className="tm-blog-meta">
                  <li><a href="blog.html"><i className="ion-android-person"></i> Anderson</a></li>
                  <li><a href="blog-details.html"><i className="ion-chatbubbles"></i> 3 Comments</a></li>
                </ul>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                  eiusmod tempor
                  incdidunt ut labore et dolore magna aliqua [....]</p>
                <a href="#" className="tm-readmore">Read more</a>
              </div>
            </div>
          </div>
          
        </div>

        <div className="tm-pagination mt-50">
          <ul>
            <li className="is-active"><a href="blog.html">1</a></li>
            <li><a href="blog.html">2</a></li>
            <li><a href="blog.html">3</a></li>
            <li><a href="blog.html">4</a></li>
            <li><a href="blog.html"><i className="ion-chevron-right"></i></a></li>
          </ul>
        </div>
      </div>
    </>
  )
}
export default BlogBody
import React from "react";
import Breadcrumbs from "../../shared/breadcrumbs/Breadcrumbs";
import ProductDescription from "../product-details/product-description/ProductDescription";
import ProductDetailsContent from "../product-details/product-details-content/ProductDetailsContent";
import ProductDetailsImage from "../product-details/product-details-image/ProductDetailsImage";
import ProductDetailsReview from "../product-details/product-details-review/ProductDetailsReview";
import SimilarProducts from "../product-details/similar-products/SimilarProducts";

function ProductDetailsNosidebar() {
  return (
    <>
      <Breadcrumbs title="Product Details" />
      <main className="tm-product-details-area tm-section tm-padding-section bg-white">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="tm-prodetails">
                <div className="row">
                  <div className="col-lg-5 col-md-6 col-sm-10 col-12">
                    <ProductDetailsImage />
                  </div>
                  <div className="col-lg-7 col-md-6 col-12">
                    <ProductDetailsContent />
                  </div>
                </div>
                <div className="tm-prodetails-desreview tm-padding-section-sm-top">
                  <ul className="nav tm-tabgroup2" id="myTab" role="tablist">
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link active"
                        id="home-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#home-tab-pane"
                        type="button"
                        role="tab"
                        aria-controls="home-tab-pane"
                        aria-selected="true"
                      >
                        Description
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link"
                        id="profile-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#profile-tab-pane"
                        type="button"
                        role="tab"
                        aria-controls="profile-tab-pane"
                        aria-selected="false"
                      >
                        Reviews
                      </button>
                    </li>
                  </ul>
                  <div className="tab-content" id="myTabContent">
                    <div
                      className="tab-pane fade show active"
                      id="home-tab-pane"
                      role="tabpanel"
                      aria-labelledby="home-tab"
                      tabIndex="0"
                    >
                      <ProductDescription />
                    </div>
                    <div
                      className="tab-pane fade"
                      id="profile-tab-pane"
                      role="tabpanel"
                      aria-labelledby="profile-tab"
                      tabIndex="0"
                    >
                      <ProductDetailsReview />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <SimilarProducts />
          </div>
        </div>
      </main>
    </>
  );
}

export default ProductDetailsNosidebar;

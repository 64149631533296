
function BlogAside(){
  return(
    <>
      <div className="col-lg-4 col-12">
        <div className="widgets widgets-sidebar">

          <div className="single-widget widget-search">
            <h6 className="widget-title">Search</h6>
            <form action="#" className="widget-search-form">
              <input type="text" placeholder="Enter your keyword..." />
              <button type="submit"><i className="ti ti-search"></i></button>
            </form>
          </div>


          <div className="single-widget widget-categories">
            <h6 className="widget-title">Categories</h6>
            <ul>
              <li><a href="blog.html">Wedding Jewelry</a></li>
              <li><a href="blog.html">Rings</a></li>
              <li><a href="blog.html">Earrings</a></li>
              <li><a href="blog.html">Bracelets</a></li>
              <li><a href="blog.html">Nacklaces</a></li>
              <li><a href="blog.html">Children’s Jewelry</a></li>
              <li><a href="blog.html">Expression Jewelry</a></li>
            </ul>
          </div>



          <div className="single-widget widget-recentpost">
            <h6 className="widget-title">Recent Posts</h6>
            <ul>
              <li>
                <a href="blog-details.html" className="widget-recentpost-image">
                  <img src="assets/images/blog/blog-thumbnail-1.jpg" alt="blog thumbnail" />
                </a>
                <div className="widget-recentpost-content">
                  <h6><a href="blog-details.html">Diamond Ring</a></h6>
                  <span>January 15, 2022</span>
                </div>
              </li>
              <li>
                <a href="blog-details.html" className="widget-recentpost-image">
                  <img src="assets/images/blog/blog-thumbnail-2.jpg" alt="blog thumbnail" />
                </a>
                <div className="widget-recentpost-content">
                  <h6><a href="blog-details.html">Couple Ring</a></h6>
                  <span>Janury 14, 2022</span>
                </div>
              </li>
              <li>
                <a href="blog-details.html" className="widget-recentpost-image">
                  <img src="assets/images/blog/blog-thumbnail-3.jpg" alt="blog thumbnail" />
                </a>
                <div className="widget-recentpost-content">
                  <h6><a href="#blog-details.html">Diamon Bracelets</a></h6>
                  <span>January 13, 2022</span>
                </div>
              </li>
            </ul>
          </div>


          <div className="single-widget widget-archives">
            <h6 className="widget-title">Archives</h6>
            <ul>
              <li><a href="#">December 2018</a></li>
              <li><a href="#">Septemper 2018</a></li>
              <li><a href="#">June 2018</a></li>
              <li><a href="#">April 2018</a></li>
              <li><a href="#">February 2018</a></li>
              <li><a href="#">January 2018</a></li>
            </ul>
          </div>


          <div className="single-widget widget-tags">
            <h6 className="widget-title">Popular Tags</h6>
            <ul>
              <li><a href="#">Make up</a></li>
              <li><a href="#">Facial</a></li>
              <li><a href="#">Hair Treatment</a></li>
              <li><a href="#">Skin Care</a></li>
              <li><a href="#">Hair Remove</a></li>
              <li><a href="#">Spa</a></li>
              <li><a href="#">Body Massage</a></li>
            </ul>
          </div>

        </div>
      </div>
    </>
  )
}
export default BlogAside
import React from "react";
import paymentMethods from "../../../assets/images/payment-methods.png";
function CopyRights() {
  return (
    <>
      <div className="tm-footer-bottomarea">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-7">
              <p className="tm-footer-copyright">
              &#169; 2022. Powered by{" "}
                <a href="https://AbarisSoftech.com">Abaris Softech</a>
              </p>
            </div>
            <div className="col-md-5">
              <div className="tm-footer-payment">
                <img src={paymentMethods} alt="payment methods" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CopyRights;

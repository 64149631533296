
function BillingInformation() {
  return (
    <>
      <div className="col-lg-6">
        <h4 className="small-title">BILLING INFORMATION</h4>
        <div className="tm-checkout-billingform">
          <div className="tm-form-inner">
            <div className="tm-form-field tm-form-fieldhalf">
              <label htmlFor="billingform-firstname">First name*</label>
              <input type="text" id="billingform-firstname" />
            </div>
            <div className="tm-form-field tm-form-fieldhalf">
              <label htmlFor="billingform-lastname">Last name*</label>
              <input type="text" id="billingform-lastname" />
            </div>
            <div className="tm-form-field">
              <label htmlFor="billingform-companyname">Company name</label>
              <input type="text" id="billingform-companyname" />
            </div>
            <div className="tm-form-field">
              <label htmlFor="billingform-email">Email address</label>
              <input type="email" id="billingform-email" />
            </div>
            <div className="tm-form-field">
              <label htmlFor="billingform-phone">Phone (Optional)</label>
              <input type="text" id="billingform-phone" />
            </div>
            <div className="tm-form-field">
              <label htmlFor="billingform-country">Country</label>
              <select name="billingform-country" id="billingform-country" className="current">
                <option value="bangladesh" className="option selected">United States</option>
                <option value="bangladesh" className="option">Mexico</option>
                <option value="bangladesh" className="option">Australia</option>
                <option value="bangladesh" className="option">Germany</option>
                <option value="bangladesh" className="option">Sweden</option>
                <option value="bangladesh" className="option">France</option>
              </select>
            </div>
            <div className="tm-form-field">
              <label htmlFor="billingform-address">Address</label>
              <input type="text" id="billingform-address" placeholder="Apartment, Street Address" />
            </div>
            <div className="tm-form-field tm-form-fieldhalf">
              <label htmlFor="billingform-streetaddress">State</label>
              <input type="text" id="billingform-streetaddress" />
            </div>
            <div className="tm-form-field tm-form-fieldhalf">
              <label htmlFor="billingform-zipcode">Zip / Postcode</label>
              <input type="text" id="billingform-zipcode" />
            </div>
            <div className="tm-form-field">
              <input type="checkbox" name="billform-dirrentswitch" id="billform-dirrentswitch" />
              <label htmlFor="billform-dirrentswitch"><b>Ship to another address</b></label>
            </div>
          </div>
        </div>
        {/* <!--// Billing Form --> */}

        {/* <!-- Different Address Form --> */}
        {/* <div className="tm-checkout-differentform">
          <div className="tm-form-inner">
            <div className="tm-form-field tm-form-fieldhalf">
              <label htmlFor="differentform-firstname">First name*</label>
              <input type="text" id="differentform-firstname"/>
            </div>
            <div className="tm-form-field tm-form-fieldhalf">
              <label htmlFor="differentform-lastname">Last name*</label>
              <input type="text" id="differentform-lastname"/>
            </div>
            <div className="tm-form-field">
              <label htmlFor="differentform-companyname">Company name</label>
              <input type="text" id="differentform-companyname"/>
            </div>
            <div className="tm-form-field">
              <label htmlFor="differentform-email">Email address</label>
              <input type="email" id="differentform-email"/>
            </div>
            <div className="tm-form-field">
              <label htmlFor="differentform-phone">Phone (Optional)</label>
              <input type="text" id="differentform-phone"/>
            </div>
            <div className="tm-form-field">
              <label htmlFor="differentform-country">Country</label>
              <select name="differentform-country" id="differentform-country" style={{ display: "none" }}>
                <option value="bangladesh">United States</option>
                <option value="bangladesh">Mexico</option>
                <option value="bangladesh">Australia</option>
                <option value="bangladesh">Germany</option>
                <option value="bangladesh">Sweden</option>
                <option value="bangladesh">France</option>
              </select><div className="nice-select open" tabIndex="0"><span className="current">United States</span><ul className="list"><li data-value="bangladesh" className="option selected">United States</li><li data-value="bangladesh" className="option">Mexico</li><li data-value="bangladesh" className="option">Australia</li><li data-value="bangladesh" className="option">Germany</li><li data-value="bangladesh" className="option">Sweden</li><li data-value="bangladesh" className="option">France</li></ul></div>
            </div>
            <div className="tm-form-field">
              <label htmlFor="differentform-address">Address</label>
              <input type="text" id="differentform-address" placeholder="Apartment, Street Address"/>
            </div>
            <div className="tm-form-field tm-form-fieldhalf">
              <label htmlFor="differentform-streetaddress">State</label>
              <input type="text" id="differentform-streetaddress"/>
            </div>
            <div className="tm-form-field tm-form-fieldhalf">
              <label htmlFor="differentform-zipcode">Zip / Postcode</label>
              <input type="text" id="differentform-zipcode"/>
            </div>
          </div>
        </div> */}
      </div>
    </>
  )
}
export default BillingInformation
import { Link } from "react-router-dom";

function MyAccount() {
  return (
    <>
      <main className="page-content">
        <div className="tm-section tm-my-account-area bg-white tm-padding-section">
          <div className="container">
            <div className="tm-myaccount">
              <ul className="nav tm-tabgroup" id="pills-tab" role="tablist">
                <li className="nav-item" role="presentation">
                  <a className="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">Dashboard</a>
                </li>

                <li className="nav-item" role="presentation">
                  <a className="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Orders</a>
                </li>

                <li className="nav-item" role="presentation">
                  <a className="nav-link" id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact" aria-selected="false">Address</a>
                </li>

                <li className="nav-item" role="presentation">
                  <a className="nav-link" id="pills-account-tab" data-bs-toggle="pill" data-bs-target="#pills-account" type="button" role="tab" aria-controls="pills-account" aria-selected="false">Account Details</a>
                </li>

                <li className="nav-item" role="presentation">
                  <Link className="nav-link" to="/login-register">Logout</Link>
                </li>
              </ul>

              <div className="tab-content" id="pills-tabContent">
                <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab" tabIndex="0">
                  {/* ---this is forst---- */}
                  <div className="tab-pane fade show active" id="account-dashboard" role="tabpanel"
                    aria-labelledby="account-dashboard-tab">
                    <div className="tm-myaccount-dashboard">
                      <p>Hello <b>William Bean</b> (not <b>William Bean</b>? <Link to="/login-register">Log
                        out</Link>)</p>
                      <p>From your account dashboard you can view your recent orders, manage your
                        shipping and billing addresses, and edit your password and account details.</p>
                    </div>
                  </div>
                </div>

                <div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab" tabIndex="0">
                  {/* ---this is second--- */}
                  <div className="tm-myaccount-orders">
                    <div className="table-responsive">
                      <table className="table table-bordered mb-0">
                        <thead>
                          <tr>
                            <th className="tm-myaccount-orders-col-id">ORDER ID</th>
                            <th className="tm-myaccount-orders-col-date">DATE</th>
                            <th className="tm-myaccount-orders-col-status">STATUS</th>
                            <th className="tm-myaccount-orders-col-total">TOTAL</th>
                            <th className="tm-myaccount-orders-col-view">VIEW</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>#12345</td>
                            <td>30 December 2018</td>
                            <td>On Hold</td>
                            <td>$132.00 for 2 items</td>
                            <td><a href="#" className="tm-button tm-button-small">View</a>
                            </td>
                          </tr>
                          <tr>
                            <td>#12346</td>
                            <td>30 December 2018</td>
                            <td>On Hold</td>
                            <td>$220.00 for 3 items</td>
                            <td><a href="#" className="tm-button tm-button-small">View</a>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

                <div className="tab-pane fade" id="pills-account" role="tabpanel" aria-labelledby="pills-account-tab" tabIndex="0">
                  <div className="tm-myaccount-acdetails">
                    <form action="#" className="tm-form tm-form-bordered">
                      <h4>Account Details</h4>
                      <div className="tm-form-inner">
                        <div className="tm-form-field tm-form-fieldhalf">
                          <label for="acdetails-firstname">First name</label>
                          <input type="text" id="acdetails-firstname" />
                        </div>
                        <div className="tm-form-field tm-form-fieldhalf">
                          <label for="acdetails-lastname">Last name</label>
                          <input type="text" id="acdetails-lastname" />
                        </div>
                        <div className="tm-form-field">
                          <label for="acdetails-displayname">Dispaly name</label>
                          <input type="text" id="acdetails-displayname" />
                        </div>
                        <div className="tm-form-field">
                          <label for="acdetails-email">Email address</label>
                          <input type="email" id="acdetails-email" />
                        </div>
                        <div className="tm-form-field">
                          <label for="acdetails-password">Old password</label>
                          <input type="password" id="acdetails-password" />
                        </div>
                        <div className="tm-form-field">
                          <label for="acdetails-newpassword">New password</label>
                          <input type="password" id="acdetails-newpassword" />
                        </div>
                        <div className="tm-form-field">
                          <label for="acdetails-confirmpass">Confirm password</label>
                          <input type="password" id="acdetails-confirmpass" />
                        </div>
                        <div className="tm-form-field">
                          <input type="checkbox" name="acdetails-agreeterms" id="acdetails-agreeterms" />
                          <label for="acdetails-agreeterms">I have read and agree to the Privacy
                            Policy</label>
                        </div>
                        <div className="tm-form-field">
                          <button type="submit" className="tm-button">Save Changes</button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>

                <div className="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab" tabIndex="0">
                  <div className="tm-myaccount-address">
                    <p><b>The following addresses will be used on the checkout page by default.</b></p>
                    <div className="row">
                      <div className="col-lg-6 col-md-6">
                        <div className="tm-myaccount-address-billing">
                          <a href="#" className="edit-button">Edit</a>
                          <h3>Billing Address</h3>
                          <address>
                            Jonathon Doe<br />
                            Example company<br />
                            516 Wintheiser Circles <br />
                            Lake Jordanmouth <br />
                            Jordan
                          </address>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 mt-30 mt-md-0">
                        <div className="tm-myaccount-address-shipping">
                          <a href="#" className="edit-button">Edit</a>
                          <h3>Shipping Address</h3>
                          <address>
                            Jonathon Doe<br />
                            Example company<br />
                            516 Wintheiser Circles <br />
                            Lake Jordanmouth <br />
                            Jordan
                          </address>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
export default MyAccount;






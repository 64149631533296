import React from 'react'
import BlogDetails from '../../components/blog-details/BlogDetails'
import Breadcrumbs from '../../shared/breadcrumbs/Breadcrumbs'

function BlogLeftSidebarPage() {
  return (
    <>
        <Breadcrumbs title="Blog Left Sidebar" />
        <BlogDetails />

    </>
  )
}

export default BlogLeftSidebarPage
import BillingInformation from "./billing-information/BillingInformation"
import BillingPaymentMethod from "./billing-payment-method/BillingPaymentMethod"


function Checkout() {
  return (
    <>
      <main className="page-content">
        <div className="tm-section tm-checkout-area bg-white tm-padding-section">
          <div className="container">

            <div className="tm-checkout-coupon">
              <a href="#checkout-couponform" data-toggle="collapse"><span>Have a coupon code?</span> Click
                here and enter your code.</a>
              <div id="checkout-couponform" className="collapse">
                <form action="#" className="tm-checkout-couponform">
                  <input type="text" id="coupon-field" placeholder="Enter coupon code" required="required" />
                  <button type="submit" className="tm-button">Submit</button>
                </form>
              </div>
            </div>

            <form action="#" className="tm-form tm-checkout-form">
              <div className="row">
                <BillingInformation />
                <BillingPaymentMethod />
              </div>
            </form>
          </div>
        </div>
      </main>
    </>
  )
}
export default Checkout